import React from 'react';
import { Box, Skeleton } from '@mui/material';

const SearchHeaderSkeleton = () => (
  <Box
    display="flex"
    alignItems="left"
    justifyContent="space-between"
    flexWrap="wrap"
    mt={1}
    mb={1.5}
    ml={1}
  >
    <Box
      display="flex"
      alignItems="left"
      flexWrap="wrap"
    >
      <Skeleton
        variant="rounded"
        height="32px"
        width="166px"
        sx={{
          marginRight: 1,
          borderRadius: '15px',
        }}
      />
      <Skeleton
        variant="rounded"
        height="32px"
        width="200px"
        sx={{
          marginRight: 1,
          borderRadius: '15px',
        }}
      />
    </Box>
    <Skeleton
      variant="rounded"
      height="32px"
      width="160px"
      sx={{
        marginBottom: 0.5,
        borderRadius: '15px 0 0 15px',
      }}
    />
  </Box>
);

export default SearchHeaderSkeleton;
