import {
  get,
  post,
  patch,
  del,
} from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const fetchMailingLists = ({ ...rest }) => get({ path: '/liste-diffusion/retrieve-all', ...rest }).then(handleComplete);

const createMailingList = ({ ...rest }) => post({ path: '/liste-diffusion', ...rest }).then(handleComplete);

const editMailingList = ({ ...rest }) => patch({ path: '/liste-diffusion', ...rest }).then(handleComplete);

const deleteMailingLists = (ids) => del({ path: `/liste-diffusion/${ids.join(',')}` }).then(handleComplete);

export {
  createMailingList,
  deleteMailingLists,
  editMailingList,
  fetchMailingLists,
};
