import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { fetchResultsComplete, refineAddFacetsValues } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';

import Highcharts from 'highcharts';

import { Grid } from '@mui/material';
import Bar from 'generic/components/dashboard-items/Bar';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import DocumentList from 'generic/components/dashboard-items/DocumentList';
import Gauge from 'generic/components/dashboard-items/SolidGauge';
import Heatmap from 'generic/components/dashboard-items/Heatmap';
import Map from 'generic/components/dashboard-items/Map';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Pie from 'generic/components/dashboard-items/Pie';
import Sankey from 'generic/components/dashboard-items/Sankey';
import Spline from 'generic/components/dashboard-items/Spline';
import Sunburst from 'generic/components/dashboard-items/Sunburst';
import Treemap from 'generic/components/dashboard-items/Treemap';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';
import WidgetContainer from 'generic/containers/WidgetContainer';
import { getColorForFrequency, getToneColor, monoColors } from 'generic/utils/colorUtils';
import { fastGedOpen } from 'generic/core/ged/actions';
import QES_CONSTANTS from 'generic/core/qes/constants';
import THEME_CONFIG from 'generic/core/theme';

const {
  DATE_INTERVAL_COMPARATOR,
} = QES_CONSTANTS;
const { entitiesColors } = THEME_CONFIG.HIGHCHARTS;

const WORDCLOUD_CONCEPTS = {
  'QES_ConceptCategorized.verbatim': { field: 30800115, label: 'concepts' },
  'QES_ConceptEnvironment.verbatim': { field: 30800115, label: 'environmental_concepts' },
  'QES_ConceptEnergy.verbatim': { field: 30800115, label: 'energy_concepts' },
  'QES_ConceptBusiness.verbatim': { field: 30800115, label: 'business_concepts' },
  'QES_ConceptPolitics.verbatim': { field: 30800115, label: 'political_concepts' },
  'QES_ConceptJustice.verbatim': { field: 30800115, label: 'justice_concepts' },
  'QES_ConceptInnovation.verbatim': { field: 30800115, label: 'innovation_concepts' },
  'QES_ConceptTechnology.verbatim': { field: 30800115, label: 'technology_concepts' },
  'QES_ConceptCulture.verbatim': { field: 30800115, label: 'culture_concepts' },
  'QES_ConceptEducation.verbatim': { field: 30800115, label: 'education_concepts' },
  'QES_ConceptSecurity.verbatim': { field: 30800115, label: 'security_concepts' },
  'QES_ConceptSociety.verbatim': { field: 30800115, label: 'society_concepts' },
  'QES_ConceptHealth.verbatim': { field: 30800115, label: 'health_concepts' },
  'QES_ConceptSport.verbatim': { field: 30800115, label: 'sport_concepts' },
};

const cooccurrenceFields = [
  { name: 'Personnes', value: 'QES_Person.verbatim', idField: 901 },
  { name: 'Sociétés', value: 'QES_Company.verbatim', idField: 1032000431 },
  { name: 'Concepts', value: 'QES_ConceptCategorized.verbatim', idField: 30800115 },
].map((field, index) => ({
  ...field,
  color: entitiesColors[index],
}));

const DashboardChartsContainer = () => {
  const strategy = useSelector((state) => state.search.results.strategie);
  const baseId = useSelector((state) => state.search.results.base);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // On utilise ici une ref pour pouvoir la mettre à jour
  // depuis le widget cooccurrences sans re-render tout le
  // dashboard
  const cooccurrencesNodesFilterValue = useRef(_.map(cooccurrenceFields, 'value'));

  const handleRefreshCooccurrencesChart = (filterValues) => {
    cooccurrencesNodesFilterValue.current = filterValues;
    const keepWidgets = true;
    const joinedValue = filterValues.join(',');
    dispatch(fetchWidgets(strategy, {
      cooccurrences: {
        facets: joinedValue,
        aggregates: [joinedValue],
        facetmax: 200,
        facetmax2: 5,
        mindoccount: 1,
        seriesNames: [joinedValue],
        type: 'networkgraph',
      },
    }, keepWidgets));
  };

  const cooccurrencesNodesFilterValueJoined = cooccurrencesNodesFilterValue.current.join(',');
  const widgets = {
    publicationDates: {
      facets: 'PUB_DATE#auto_date_histogram|300 _key:asc',
      aggregates: ['agg_PUB_DATE'],
      seriesNames: [t('dashboard.widget.publication_date')],
      type: 'spline',
    },
    sources: {
      facets: 'SOURCE.verbatim',
      aggregates: ['agg_SOURCE.verbatim'],
      type: 'pie',
    },
    publicationAndHarvestDates: {
      facets: 'PUB_DATE#auto_date_histogram|300 _key:asc; DATE_COLLECT#auto_date_histogram|300 _key:asc',
      aggregates: ['agg_PUB_DATE', 'agg_DATE_COLLECT'],
      seriesNames: [t('dashboard.widget.publication_date'), t('dashboard.widget.collect_date')],
      type: 'spline',
    },
    documentStatus: {
      facets: 'ETAT',
      aggregates: ['agg_ETAT'],
      type: 'treemap',
    },
    documentGaugeStatus: {
      facets: 'ETAT',
      aggregates: ['agg_ETAT'],
      type: 'solidgauge',
    },
    sentiments: {
      facets: 'QES_SentimentForce',
      aggregates: ['agg_QES_SentimentForce'],
      type: 'pie',
    },
    conceptOnCountries: {
      facets: 'QES_CountryTopicCode.verbatim, QES_Concept.verbatim',
      aggregates: ['agg_QES_CountryTopicCode.verbatim'],
      pivots: ['agg_QES_Concept.verbatim'],
      seriesNames: [t('dashboard.widget.concepts_cited_by_country')],
      type: 'heatmap',
      mindoccount: 1,
      facetmax: 5,
      facetmax2: 5,
    },
    locations: {
      facets: 'QES_Location.verbatim',
      aggregates: ['agg_QES_Location.verbatim'],
      seriesNames: [t('dashboard.widget.places_mentionned')],
      type: 'bar',
    },
    lastPublishedDocuments: {
      additionalQuery: 'ETAT:100',
      slice: 25,
      sort: 'DATE_COLLECT Desc',
      type: 'documentlist',
    },
    countryTopic: {
      facets: 'QES_CountryTopicCode.verbatim',
      aggregates: ['agg_QES_CountryTopicCode.verbatim'],
      type: 'map',
      mindoccount: 1,
      facetmax: 10000,
      facetmax2: 10000,
    },
    conceptsPersons: {
      facets: 'QES_Person.verbatim, QES_Concept.verbatim',
      aggregates: ['agg_QES_Person.verbatim'],
      pivots: ['agg_QES_Concept.verbatim'],
      facetmax: 5,
      facetmax2: 10,
      mindoccount: 1,
      type: 'sankey',
    },
    sourcesByOriginFolders: {
      facets: 'FOLDER_ID.verbatim, SOURCE.verbatim',
      aggregates: ['agg_FOLDER_ID.verbatim'],
      pivots: ['agg_SOURCE.verbatim'],
      list: 1001701270,
      type: 'sunburst',
    },
    persons: {
      facets: 'QES_Person.verbatim',
      aggregates: ['agg_QES_Person.verbatim'],
      type: 'pie',
    },
    companies: {
      facets: 'QES_Company.verbatim',
      aggregates: ['agg_QES_Company.verbatim'],
      type: 'pie',
    },
    concepts: {
      facets: _.keys(WORDCLOUD_CONCEPTS).join(';'),
      aggregates: _.keys(WORDCLOUD_CONCEPTS).map((c) => `agg_${c}`),
      seriesNames: _.map(WORDCLOUD_CONCEPTS, 'label'),
      facetmax: 50,
      facetmax2: 3,
      type: 'wordcloud',
      splitPerSerie: true,
    },
    cooccurrences: {
      facets: cooccurrencesNodesFilterValueJoined,
      aggregates: [cooccurrencesNodesFilterValueJoined],
      facetmax: 200,
      facetmax2: 5,
      mindoccount: 1,
      seriesNames: [cooccurrencesNodesFilterValueJoined],
      type: 'networkgraph',
    },
  };

  const handleExportWidget = (widgetId, facet) => {
    const keepWidgets = true;
    const params = widgets[widgetId];
    if (facet) {
      params.facets = facet;
    }
    params.facetmax = 100000;
    params.exportWidget = true;
    dispatch(fetchWidgets(strategy, {
      widgetId: params,
    }, keepWidgets));
  };

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      dispatch(fetchWidgets(strategy, widgets));
    }

    // On ne met pas cooccurrencesNodesFilterValue dans les dépendances, pour
    // éviter de refresh tout le dashboard quand on change juste
    // le type des éléments affichés (refresh géré dans
    // handleChangeCooccurrenceValue).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, strategy]);

  return (
    <Grid
      container
      spacing={1}
      className="desktopOnlyOverflow"
      p={1}
    >
      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="publicationDates" height={275}>
          {({ series: publicationDatesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={ColumnHisto}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: {
                    text: t('dashboard.widget.documents_published_by_dates'),
                    align: 'left',
                  },
                  series: publicationDatesSerie,
                  yAxis: { title: { text: t('dashboard.widget.number_articles') } },
                  legend: false,
                  xAxis: {
                    title: { text: '' },
                    startOnTick: true,
                    endOnTick: true,
                    gridLineWidth: 0,
                    dateTimeLabelFormats: {
                      second: '%d/%m/%Y<br/>%H:%M:%S',
                      minute: '%d/%m/%Y<br/>%H:%M',
                      hour: '%d/%m/%Y<br/>%H:%M',
                      day: '%Y<br/>%d/%m',
                      month: '%m/%Y',
                      year: '%Y',
                    },
                    events: {
                      afterSetExtremes: (e) => {
                        if (e.trigger === 'zoom') {
                          const min = publicationDatesSerie[0].data[0][0];
                          const max = publicationDatesSerie[0].data[
                            publicationDatesSerie[0].data.length - 1
                          ][0];
                          let valmin = e.min;
                          let valmax = e.max;
                          if (valmin < min) {
                            valmin = min;
                          }
                          if (valmax > max) {
                            valmax = max;
                          }
                          dispatch(refineAddFacetsValues([
                            {
                              champ: 10000029,
                              formatFacet: 'date',
                              comparator: DATE_INTERVAL_COMPARATOR.between,
                              begin: format(new Date(valmin), 'yyyyMMdd'),
                              end: format(new Date(valmax), 'yyyyMMdd'),
                            },
                          ]));
                        }
                      },
                    },
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="sources">
          {({ series: sourcesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              handleExportWidget={() => handleExportWidget('sources')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  colors: monoColors(0.35, _.get(sourcesSerie, '[0].data.length', 10), 0),
                  plotOptions: {
                    pie: {
                      data: _.get(sourcesSerie, '[0].data', []),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([{ champ: 27, strategie: event.point.options.strategy }]));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: t('dashboard.widget.number_articles') },
                  ],
                  title: {
                    text: t('dashboard.widget.sources_mentioned'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <WidgetContainer widgetId="publicationAndHarvestDates">
          {({ series: publicationAndHarvestDatesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Spline}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: {
                    text: t('dashboard.widget.documents_published_collected_by_dates'),
                    align: 'left',
                  },
                  series: publicationAndHarvestDatesSerie,
                  yAxis: { title: { text: t('dashboard.widget.number_articles') } },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="documentStatus">
          {({ series: documentStatusSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Treemap}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    treemap: {
                      animation: false,
                      colorByPoint: true,
                      dataLabels: {
                        formatter: function labelFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`ged.document.status.${this.point.name}`)}`;
                        },
                      },
                      tooltip: {
                        pointFormatter: function tooltipFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`ged.document.status.${this.name}`)}: ${this.value}`;
                        },
                      },
                      layoutStartingDirection: 'horizontal',
                      layoutAlgorithm: 'squarified',
                      point: {
                        events: {
                          click: (e) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 110, strategie: e.point.name, dontQuoteStrategy: true },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  series: documentStatusSerie,
                  title: {
                    text: t('dashboard.widget.status_documents'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="documentGaugeStatus">
          {({ series: documentGaugeStatusSerie, axisX, height }) => (
            <DashboardWidget
              height={height}
              component={Gauge}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    solidgauge: {
                      events: {
                        click: (e) => {
                          dispatch(refineAddFacetsValues([
                            { champ: 110, strategie: e.point.name, dontQuoteStrategy: true },
                          ]));
                        },
                      },
                    },
                  },
                  pane: {
                    background: axisX.categories.map((aggregate, index) => ({
                      outerRadius: aggregate.outerRadius,
                      innerRadius: aggregate.innerRadius,
                      borderWidth: 0,
                      backgroundColor: `${Highcharts.getOptions().colors[9 - (index * 2)]}4D`,
                    })),
                  },
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    align: 'center',
                    // eslint-disable-next-line func-names, object-shorthand
                    labelFormatter: function () {
                      // eslint-disable-next-line react/no-this-in-sfc
                      return `${t(`ged.document.status.${this.name}`)} - ${this.data[0].y}%`;
                    },
                  },
                  series: _.get(documentGaugeStatusSerie, '[0].data', []).map((aggregate, index) => ({
                    name: aggregate.name,
                    data: aggregate.data.map((aggdata) => ({
                      radius: aggdata.radius,
                      innerRadius: aggdata.innerRadius,
                      name: aggdata.name,
                      y: aggdata.y,
                      color: Highcharts.getOptions().colors[9 - (index * 2)],
                    })),
                    color: Highcharts.getOptions().colors[9 - (index * 2)],
                    showInLegend: true,
                  })),
                  title: {
                    text: t('dashboard.widget.status_documents'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="sentiments">
          {({ series: sentimentsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.sortBy(_.get(sentimentsSerie, '[0].data', []), 'name').map(
                        (item) => ({
                          ...item,
                          color: getToneColor(item.name),
                        }),
                      ),
                      point: {
                        events: {
                          click: (e) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 1032000567, strategie: e.point.name },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  title: {
                    text: t('dashboard.widget.tonalities'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="conceptOnCountries">
          {({
            series: conceptOnCountriesSerie,
            axisX,
            axisY,
            height,
          }) => (
            <DashboardWidget
              height={height}
              component={Heatmap}
              handleExportWidget={() => handleExportWidget('conceptOnCountries')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: {
                    text: t('dashboard.widget.concepts_by_country'),
                    align: 'left',
                  },
                  series: conceptOnCountriesSerie,
                  xAxis: {
                    categories: axisX
                      .categories
                      .map((countryCode) => t(`dashboard.countries.${countryCode}`)),
                  },
                  yAxis: {
                    categories: axisY.categories,
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="countryTopic">
          {({ series: countryTopicSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Map}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      tooltip: {
                        pointFormatter: function getTranslatedCountryName() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                        },
                      },
                      point: {
                        events: {
                          click: (e) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 42, strategie: e.point.options.name, dontQuoteStrategy: true },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  series: countryTopicSerie,
                  title: {
                    text: t('dashboard.widget.countries_mentionned'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="lastPublishedDocuments">
          {({ series: lastPublishedDocs, height }) => (
            <DashboardWidget
              height={height}
              component={DocumentList}
              componentProps={{
                title: { text: t('dashboard.widget.latest_published_documents'), align: 'left' },
                qesdocuments: _.get(lastPublishedDocs, '[0]', []),
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, baseId)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, baseId)),
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <WidgetContainer widgetId="locations">
          {({ series: locationsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Bar}
              handleExportWidget={() => handleExportWidget('locations')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  tooltip: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (e) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 902, strategie: e.point.name },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  title: {
                    text: t('dashboard.widget.places_mentionned_in_documents'),
                    align: 'left',
                  },
                  yAxis: {
                    title: {
                      text: t('dashboard.widget.values'),
                    },
                  },
                  series: locationsSerie,
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <WidgetContainer widgetId="conceptsPersons">
          {({ series: conceptsPersonsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Sankey}
              handleExportWidget={() => handleExportWidget('conceptsPersons')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (e) => {
                            const nodesIdsMap = {
                              0: 901,
                              1: 30800115,
                            };
                            let fields;
                            if (e.point.from) {
                              // On a cliqué sur un lien, donc on prépare le double raffinage
                              fields = [
                                { champ: nodesIdsMap[e.point.fromNode.column], strategie: e.point.fromNode.name },
                                { champ: nodesIdsMap[e.point.toNode.column], strategie: e.point.toNode.name },
                              ];
                            } else {
                              // On a cliqué sur un noeud, on fait un simple raffinage
                              fields = [
                                { champ: nodesIdsMap[e.point.column], strategie: e.point.name },
                              ];
                            }
                            dispatch(refineAddFacetsValues(fields));
                          },
                        },
                      },
                    },
                  },
                  series: conceptsPersonsSerie,
                  title: {
                    text: t('dashboard.widget.persons_and_concepts'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="sourcesByOriginFolders">
          {({ series: sourcesByOriginFoldersSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Sunburst}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    sunburst: {
                      point: {
                        events: {
                          click: (e) => {
                            if (e.point.name && e.point.node.isLeaf) {
                              dispatch(refineAddFacetsValues([{ champ: 27, strategie: e.point.name }]));
                            } else if (e.shiftKey && e.point.node.index !== 0) {
                              dispatch(refineAddFacetsValues([{ champ: 1032000564, strategie: e.point.parent }]));
                            }
                          },
                        },
                      },
                    },
                  },
                  series: [{
                    data: _.get(sourcesByOriginFoldersSerie, '[0].data', []),
                  }],
                  title: {
                    text: t('dashboard.widget.sources_by_original_folders'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="companies">
          {({ series: companiesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              handleExportWidget={() => handleExportWidget('companies')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.get(companiesSerie, '[0].data', []),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 1032000431, strategie: event.point.options.strategy },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: t('dashboard.widget.number_articles_compagnies') },
                  ],
                  title: {
                    text: t('dashboard.widget.compagnies_mentionned'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="persons">
          {({ series: personsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              handleExportWidget={() => handleExportWidget('persons')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.get(personsSerie, '[0].data', []),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([{ champ: 901, strategie: event.point.options.strategy }]));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: t('dashboard.widget.number_articles_persons') },
                  ],
                  title: {
                    text: t('dashboard.widget.persons_mentionned'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {_.entries(WORDCLOUD_CONCEPTS).map(([fieldName, fieldDefinition]) => (
        <Grid item xs={12} sm={6} md={fieldName === 'Categorized' ? 8 : 4} key={`concepts${fieldName}`}>
          <WidgetContainer widgetId={`concepts_${fieldDefinition.label}`}>
            {({ series: conceptsSerie, height }) => (
              <DashboardWidget
                height={height}
                component={Wordcloud}
                handleExportWidget={() => handleExportWidget('concepts', fieldName)}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      wordcloud: {
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: fieldDefinition.field,
                                  strategie: event.point.options.name,
                                },
                              ]));
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      data: _.map(conceptsSerie.data, ([word, weight]) => ({
                        name: word,
                        weight,
                        color: getColorForFrequency(0.35, conceptsSerie.minFreq, conceptsSerie.maxFreq, weight, 0),
                      })),
                      name: t(`dashboard.widget.${conceptsSerie.name}`),
                    }],
                    title: {
                      text: t(`dashboard.widget.${conceptsSerie.name}`),
                      align: 'left',
                    },
                  },
                }}
              />
            )}
          </WidgetContainer>
        </Grid>
      ))}

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="cooccurrences" height={700}>
          {({ series: cooccurrenceCompany, height }) => (
            <DashboardWidget
              height={height}
              component={NetworkGraph}
              componentProps={{
                nodesFilterValue: cooccurrencesNodesFilterValue.current,
                nodesFilterItems: cooccurrenceFields,
                handleRefreshChart: handleRefreshCooccurrencesChart,
                handleLinkClick: (fromNode, toNode) => {
                  const {
                    idField: fromIdField,
                  } = _.find(cooccurrenceFields, { value: fromNode.options.group });
                  const {
                    idField: toIdField,
                  } = _.find(cooccurrenceFields, { value: toNode.options.group });
                  const facetsValues = [{
                    champ: fromIdField,
                    strategie: fromNode.id,
                  }];
                  if (fromIdField === toIdField) {
                    // Si jamais l'ID de champ est le même pour les deux noeuds,
                    // on colle les deux valeurs dans un même tableau, qui sera
                    // découpé convenablement au moment du fetch des résultats
                    facetsValues[0].strategie = [fromNode.id, toNode.id];
                  } else {
                    // A l'inverse, si les ID de champs sont différents, on
                    // on ajoute une seconde "facetValue" avec les éléments
                    // du second noeud
                    facetsValues.push({
                      champ: toIdField,
                      strategie: toNode.id,
                    });
                  }
                  dispatch(refineAddFacetsValues(facetsValues));
                },
              }}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (e) => {
                            const { idField } = _.find(cooccurrenceFields, { value: e.point.options.group });
                            if (idField) {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: idField,
                                  strategie: e.point.name,
                                },
                              ]));
                            }
                          },
                        },
                      },
                    },
                  },
                  series: [{
                    nodes: _.get(cooccurrenceCompany, '[0].nodes', []).map(
                      (node) => ({
                        ...node,
                        color: _.find(cooccurrenceFields, { value: node.group })?.color,
                      }),
                    ),
                    data: _.get(cooccurrenceCompany, '[0].data', []),
                  }],
                  title: {
                    text: t('dashboard.widget.cooccurrences_graph'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
    </Grid>
  );
};

export default React.memo(DashboardChartsContainer);
