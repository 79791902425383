const themeConfig = {
  GLOBAL_STYLES: {
    '@global': {
      '.html-with-tags > .html-tag:not(.Person)': {
        backgroundColor: 'unset',
        border: 'unset',
        padding: '0',
      },
      '.html-with-tags > .html-tag': {
        '&::after': {
          content: 'unset',
        },
        '&.Person': {
          backgroundColor: '#ff000045',
        },
      },
    },
  },
};

export default themeConfig;
