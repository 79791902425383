import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';

import {
  createMailingListError,
  createMailingListSuccess,
  deleteMailingListsError,
  deleteMailingListsSuccess,
  editMailingListError,
  editMailingListSuccess,
  fetchMailingLists,
  fetchMailingListsError,
  fetchMailingListsSuccess,
  types,
} from 'generic/core/mailingLists/actions';
import {
  createMailingList,
  deleteMailingLists,
  editMailingList,
  fetchMailingLists as fetchMailingListsApi,
} from 'generic/api/mailingList';

import { snackActions } from 'generic/utils/snackbar';

function* workFetchMailingLists() {
  try {
    const mailingLists = yield call(fetchMailingListsApi);
    yield put(fetchMailingListsSuccess(mailingLists));
  } catch (error) {
    yield put(fetchMailingListsError());
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_fetching'));
  }
}

function* watchFetchMailingLists() {
  yield takeLatest(types.FETCH_MAILING_LISTS, workFetchMailingLists);
}

function* workCreateMailingList({ formValues }) {
  try {
    const params = {
      code: new Date().getTime(),
      libelle: formValues.libelle,
      plateforme: 1000,
    };
    yield call(createMailingList, { bodyItems: { ...params } });
    yield put(createMailingListSuccess());
    snackActions.success(i18next.t('mailing_lists.created'));
    yield put(fetchMailingLists());
  } catch (error) {
    yield put(createMailingListError());
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_creation'));
  }
}

function* watchCreateMailingList() {
  yield takeLatest(types.CREATE_MAILING_LIST, workCreateMailingList);
}

function* workEditMailingList({ formValues }) {
  try {
    const params = {
      listeDiffusion: formValues.id,
      libelle: formValues.libelle,
    };
    yield call(editMailingList, { bodyItems: { ...params } });
    yield put(editMailingListSuccess());
    snackActions.success(i18next.t('mailing_lists.edited'));
    yield put(fetchMailingLists());
  } catch (error) {
    yield put(editMailingListError());
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_editing'));
  }
}

function* watchEditMailingList() {
  yield takeLatest(types.EDIT_MAILING_LIST, workEditMailingList);
}

function* workDeleteMailingLists({ ids }) {
  try {
    yield call(deleteMailingLists, ids);
    yield put(deleteMailingListsSuccess());
    snackActions.success(i18next.t('mailing_lists.deleted', { count: ids.length }));
    yield put(fetchMailingLists());
  } catch (error) {
    yield put(deleteMailingListsError(error));
    console.error(error);
    snackActions.error(i18next.t('mailing_lists.error_deleting', { count: ids.length }));
  }
}

function* watchDeleteMailingLists() {
  yield takeLatest(types.DELETE_MAILING_LISTS, workDeleteMailingLists);
}

export default {
  watchFetchMailingLists,
  watchDeleteMailingLists,
  watchCreateMailingList,
  watchEditMailingList,
};
