import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import {
  List,
  ListItem,
  Box,
  Checkbox,
  Drawer,
  Pagination,
  Stack,
  Button,
  Select,
  InputAdornment,
  MenuItem,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { overrideRessource } from 'generic/utils/utils';
import SelectionContainer from 'generic/containers/SelectionContainer';
import ResultsActions from 'generic/components/ui/ResultsActions';
import ResultSingleActions from 'generic/components/ui/ResultSingleAction';
import ResultItemActionsMenu from 'generic/components/ui/ResultItemActionsMenu';
import SortContainer from 'generic/containers/SortContainer';
import CommentsContainer from 'generic/containers/CommentsContainer';
import MoreLikeThis from 'generic/components/ui/MoreLikeThis';
import DocumentsListSkeleton from 'generic/components/skeletons/DocumentsListSkeleton';

import { actionsPropType } from 'generic/core/qes/proptypes';
import clsx from 'clsx';
import { NotificationAddOutlined } from '@mui/icons-material';
import ResultsCompleteContainer from 'generic/containers/ResultsCompleteContainer';

const DocumentWrapper = overrideRessource('components/documents/DocumentWrapper');

const useStyles = makeStyles((theme) => ({
  root: {
    '& .qes_highlight_keyword': theme.custom.highlightKeyword,
  },
}));

const Results = ({
  allArticlesChecked,
  buttonsRights,
  canComment,
  canCreateAlerts,
  canSortResults,
  withMoreLikeThis,
  checkedItems,
  handleChangeRowsPerPage,
  handleFetchMoreLikeThis,
  handleGetDocumentComplete,
  handleOpenAddToCart,
  handleOpenAddToNewsletter,
  handleOpenCreateAlert,
  handleOpenMultipleGed,
  handleOpenSendToFriend,
  handleOpenExport,
  handleOpenDeleteArticleDialog,
  handleOpenUpdateStatusArticleDialog,
  handlePageChangePagination,
  handleToggleCheckbox,
  handleToggleCheckboxAll,
  loading,
  loadingBlank,
  results,
  rowsPerPageList,
  openFastGed,
  someArticlesChecked,
  resultsCompleteVisibleInResults,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openSelectionStatus, setOpenSelectionStatus] = useState(false);
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleMoreLikeThis, setVisibleMoreLikeThis] = useState({});
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const noResultsFound = !loading && _.isEmpty(results.documents);
  const showAtLeastOneButton = _.some(buttonsRights, Boolean);

  useEffect(() => {
    if (_.isEmpty(checkedItems)) {
      setOpenSelectionStatus(false);
    }
  }, [checkedItems]);

  useEffect(() => {
    if (!loading) {
      setVisibleComments({});
      setVisibleMoreLikeThis({});
    }

    if (document.getElementById('pageScroll')) {
      document.getElementById('pageScroll').scrollTop = 0;
    }
  }, [loading]);

  const handleToggleComments = (document) => {
    setVisibleComments({ ...visibleComments, [document.idext]: !visibleComments[document.idext] });
  };

  const handleToggleMoreLikeThis = (document) => {
    setVisibleMoreLikeThis({ ...visibleMoreLikeThis, [document.idext]: !visibleMoreLikeThis[document.idext] });
    if (_.isEmpty(document.morelikethis)) {
      handleFetchMoreLikeThis(document);
    }
  };

  let documentsListStyle = { width: '100%' };
  if (resultsCompleteVisibleInResults) {
    documentsListStyle = { flexShrink: '0', flexBasis: '600px' };
  }
  return (
    <Box
      className={clsx(classes.root, 'desktopOnlyOverflow')}
      width="100%"
      id="pageScroll"
      display="flex"
      flexDirection="column"
    >
      {results.qaReponse && (
        <Box p={4} mb={2} backgroundColor="#f5f5f5">
          <Box p={2} borderLeft="5px solid #d3d3d3" fontSize="18px" whiteSpace="pre-wrap" lineHeight="24px">
            {results.qaReponse}
          </Box>
        </Box>
      )}
      <Box
        backgroundColor="white"
        display="flex"
        flexWrap="wrap"
        gap={1}
        justifyContent="space-between"
        position="sticky"
        p="12px"
        px={smallerThanMedium ? 1 : 2}
        top="0"
        zIndex="1200"
      >
        {/* Quand loadingBlank vaut true (après un clearResults),
        on affiche le Skeleton de la toolbar */}
        {loadingBlank && (
          <Box pl={1} width="100%">
            <Skeleton variant="rectangular" height="32px" width="100%" />
          </Box>
        )}
        {/* Quand loadingBlank vaut false on affiche la toolbar */}
        {!loadingBlank && (
          <Fragment>
            <Stack
              direction="row"
              gap={1}
              flexWrap="wrap"
            >
              {showAtLeastOneButton && (
                <Fragment>
                  <Checkbox
                    sx={{ m: 0, padding: '4px 4px 4px 5px' }}
                    checked={allArticlesChecked}
                    indeterminate={!allArticlesChecked && someArticlesChecked}
                    onChange={handleToggleCheckboxAll}
                  />
                  <ResultsActions
                    canAddToCart={buttonsRights.canAddToCart}
                    canAddToNewsletter={buttonsRights.canAddToNewsletter}
                    canEditInGED={buttonsRights.canEditInGED}
                    canDeleteDocument={buttonsRights.canDeleteDocument}
                    canSendToFriend={buttonsRights.canSendToFriend}
                    canExport={buttonsRights.canExport}
                    loading={loading}
                    handleOpenSelection={() => setOpenSelectionStatus(true)}
                    handleOpenAddToCart={handleOpenAddToCart}
                    handleOpenAddToNewsletter={handleOpenAddToNewsletter}
                    handleOpenSendToFriend={handleOpenSendToFriend}
                    handleOpenDeleteArticleDialog={handleOpenDeleteArticleDialog}
                    handleOpenExport={handleOpenExport}
                    actions={results.actions}
                    handleOpenUpdateStatusArticleDialog={handleOpenUpdateStatusArticleDialog}
                    handleOpenMultipleGed={handleOpenMultipleGed}
                    nbCheckedItems={_.size(checkedItems)}
                  />
                </Fragment>
              )}
            </Stack>
            {!results.qaReponse && (
              <Box
                display={noResultsFound ? 'none' : 'flex'}
                flexWrap="wrap"
                flexShrink="0"
              >
                {!smallerThanLarge && (
                  <Fragment>
                    {canSortResults && (
                      <SortContainer />
                    )}
                    {!_.isEmpty(rowsPerPageList) && (
                      <Select
                        onChange={handleChangeRowsPerPage}
                        startAdornment={
                          <InputAdornment position="start">{t('results.pagination.display_rows')}</InputAdornment>
                        }
                        sx={{ mr: 1 }}
                        value={results.tranche}
                        variant="standard"
                      >
                        {(
                          rowsPerPageList.map((nb) => (
                            <MenuItem
                              key={nb}
                              value={nb}
                            >
                              {nb}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    )}
                  </Fragment>
                )}
                <Pagination
                  count={Math.ceil(results.nbresults / results.tranche)}
                  page={Math.ceil(results.premier / results.tranche)}
                  onChange={handlePageChangePagination}
                  showFirstButton
                  showLastButton
                  siblingCount={0}
                  boundaryCount={smallerThanLarge ? 0 : 1}
                  size="small"
                  color="secondary"
                />
              </Box>
            )}
          </Fragment>
        )}
      </Box>

      {/* Quand loading vaut false et qu'aucun doc n'a été ramené
      on affiche le message "aucun résultat" */}
      {noResultsFound && (
        <Box mt="150px" position="fixed" textAlign="center" width="100%">
          <Box px={2}>
            {canCreateAlerts ? (
              t('results.no_search_results_create_alert')
            ) : (
              t('results.no_search_results')
            )}
            {canCreateAlerts && (
              <Button
                size="extraSmall"
                color="secondary"
                sx={{
                  boxShadow: 'none',
                  '&:active': { boxShadow: 'none' },
                  ml: '5px',
                  p: '5px 10px',
                  minWidth: 'auto',
                }}
                onClick={handleOpenCreateAlert}
              >
                <NotificationAddOutlined />
              </Button>
            )}
          </Box>
        </Box>
      )}
      <Box display="flex" overflow={results.qaReponse ? 'unset' : 'auto'}>
        {/* Quand loading vaut true on affiche le Skeleton des résultats */}
        {loading ? (
          <Box sx={documentsListStyle} overflow="auto">
            <DocumentsListSkeleton
              nbDocs={results.tranche || 20}
            />
          </Box>
        ) : (
          !_.isEmpty(results.documents) && (
            <List dense className="overflowAuto" sx={documentsListStyle}>
              {
                results.documents.map(
                  (document) => {
                    let commentsButtonLabel = t('results.comments.comment');
                    if (visibleComments[document.idext]) {
                      commentsButtonLabel = t('results.comments.hide_comments');
                    } else if (!_.isEmpty(document.commentaires)) {
                      commentsButtonLabel = t('results.comments.show_comments', {
                        count: document.commentaires.length,
                      });
                    }
                    let seeAlsoButtonLabel = t('results.morelikethis.show_see_also');
                    if (visibleMoreLikeThis[document.idext]) {
                      seeAlsoButtonLabel = t('results.morelikethis.hide_see_also');
                    }
                    return (
                      <ListItem
                        alignItems="flex-start"
                        key={document.documentitem}
                        sx={{ px: smallerThanMedium ? 1 : 2 }}
                      >
                        {showAtLeastOneButton && (
                          <Box
                            display="flex"
                            flexDirection="column"
                          >
                            <Checkbox
                              sx={{ margin: 0 }}
                              onClick={() => handleToggleCheckbox(document)}
                              checked={checkedItems[document.idext] !== undefined}
                            />
                            <Box
                              display="flex"
                              justifyContent="end"
                            >
                              <ResultItemActionsMenu>
                                <ResultSingleActions
                                  canAddToCart={buttonsRights.canAddToCart}
                                  canAddToNewsletter={buttonsRights.canAddToNewsletter}
                                  canEditInGED={buttonsRights.canEditInGED}
                                  canFastEditInGed={buttonsRights.canFastEditInGed}
                                  canDeleteDocument={buttonsRights.canDeleteDocument}
                                  canSendToFriend={buttonsRights.canSendToFriend}
                                  handleOpenFastGed={() => { openFastGed(document.idext, document.base); }}
                                  document={document}
                                />
                              </ResultItemActionsMenu>
                            </Box>
                          </Box>
                        )}
                        <Box width="100%">
                          <DocumentWrapper
                            document={document}
                            disableTagsRefine={results.qaReponse}
                            handleGetDocumentComplete={() => handleGetDocumentComplete(document)}
                            shrinkDocumentDisplay={resultsCompleteVisibleInResults}
                          />
                          <Stack direction="row" gap={1} flexWrap="wrap">
                            {withMoreLikeThis && (
                              <Button
                                variant="text"
                                disableElevation
                                color="secondary"
                                size="extraSmall"
                                sx={{ textTransform: 'none' }}
                                onClick={() => handleToggleMoreLikeThis(document)}
                              >
                                {seeAlsoButtonLabel}
                              </Button>
                            )}
                            {canComment && (
                              <Button
                                variant="text"
                                disableElevation
                                size="extraSmall"
                                color="secondary"
                                sx={{ textTransform: 'none' }}
                                onClick={() => handleToggleComments(document)}
                              >
                                {commentsButtonLabel}
                              </Button>
                            )}
                          </Stack>
                          {withMoreLikeThis && (
                            <MoreLikeThis
                              qesdocuments={document.morelikethis}
                              loading={document.loadingMoreLikeThis}
                              moreLikeThisIsVisible={visibleMoreLikeThis[document.idext]}
                            />
                          )}
                          {canComment && (
                            <CommentsContainer
                              document={document}
                              commentIsVisible={visibleComments[document.idext]}
                            />
                          )}
                        </Box>
                      </ListItem>
                    );
                  },
                )
              }
            </List>
          )
        )}
        <ResultsCompleteContainer />
      </Box>
      <Drawer
        anchor="right"
        variant="temporary"
        open={openSelectionStatus}
        onClose={() => setOpenSelectionStatus(false)}
        PaperProps={{ sx: { width: { xs: '80%', md: '50%', lg: '30%' } } }}
      >
        <SelectionContainer
          actions={results.actions}
          canAddToCart={buttonsRights.canAddToCart}
          canAddToNewsletter={buttonsRights.canAddToNewsletter}
          canEditInGED={buttonsRights.canEditInGED}
          canExport={buttonsRights.canExport}
          canDeleteDocument={buttonsRights.canDeleteDocument}
          canSendToFriend={buttonsRights.canSendToFriend}
          handleOpenAddToCart={handleOpenAddToCart}
          handleOpenAddToNewsletter={handleOpenAddToNewsletter}
          handleOpenSendToFriend={handleOpenSendToFriend}
          handleOpenMultipleGed={handleOpenMultipleGed}
        />
      </Drawer>
    </Box>
  );
};

Results.propTypes = {
  allArticlesChecked: PropTypes.bool,
  buttonsRights: PropTypes.shape({
    canAddToCart: PropTypes.bool,
    canAddToNewsletter: PropTypes.bool,
    canEditInGED: PropTypes.bool,
    canFastEditInGed: PropTypes.bool,
    canDeleteDocument: PropTypes.bool,
    canSendToFriend: PropTypes.bool,
    canExport: PropTypes.bool,
  }),
  canCreateAlerts: PropTypes.bool,
  canComment: PropTypes.bool,
  canSortResults: PropTypes.bool,
  withMoreLikeThis: PropTypes.bool,
  checkedItems: PropTypes.shape(),
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleFetchMoreLikeThis: PropTypes.func.isRequired,
  handleGetDocumentComplete: PropTypes.func.isRequired,
  handleOpenAddToCart: PropTypes.func.isRequired,
  handleOpenAddToNewsletter: PropTypes.func.isRequired,
  handleOpenCreateAlert: PropTypes.func.isRequired,
  handleOpenMultipleGed: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleOpenDeleteArticleDialog: PropTypes.func.isRequired,
  handleOpenUpdateStatusArticleDialog: PropTypes.func.isRequired,
  handleOpenExport: PropTypes.func.isRequired,
  handlePageChangePagination: PropTypes.func.isRequired,
  handleToggleCheckbox: PropTypes.func.isRequired,
  handleToggleCheckboxAll: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingBlank: PropTypes.bool,
  results: PropTypes.shape({
    mouvement: PropTypes.number,
    nbresults: PropTypes.number,
    premier: PropTypes.number,
    tranche: PropTypes.number,
    actions: PropTypes.arrayOf(actionsPropType),
    documents: PropTypes.arrayOf(PropTypes.shape()),
    qaReponse: PropTypes.string,
  }),
  rowsPerPageList: PropTypes.arrayOf(PropTypes.number),
  openFastGed: PropTypes.func,
  someArticlesChecked: PropTypes.bool,
  resultsCompleteVisibleInResults: PropTypes.bool,
};

Results.defaultProps = {
  allArticlesChecked: false,
  buttonsRights: {
    canAddToCart: false,
    canAddToNewsletter: false,
    canEditInGED: false,
    canFastEditInGed: false,
    canDeleteDocument: false,
    canSendToFriend: false,
    canExport: false,
  },
  canCreateAlerts: false,
  canComment: false,
  canSortResults: false,
  withMoreLikeThis: true,
  checkedItems: {},
  loading: false,
  loadingBlank: false,
  results: {
    tranche: 20,
    actions: [],
  },
  rowsPerPageList: null,
  openFastGed: _.noop,
  someArticlesChecked: false,
  resultsCompleteVisibleInResults: false,
};

export default Results;
