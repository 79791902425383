import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _, { merge } from 'lodash';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlined,
  CheckCircle,
  CheckCircleOutline,
} from '@mui/icons-material';

HighchartsNoData(Highcharts);

/**
 * @description
 * Nécessite l'import `NetworkGraphWrapper` au plus haut possible dans l'arborescence, généralement dans `App.jsx` sous
 * `ConfigWrapper`
 * @example
 * ```jsx
 * import NetworkGraphWrapper from 'generic/components/dashboard-items/highchart-extensions/NetworkGraphWrapper';
 * <Provider store={store}>
 *    <ConfigWrapper>
 *      <NetworkGraphWrapper />
 * ```
 * @param {Object} props
 * @returns {React.ComponentElement} NetworkGraph component
 */
const NetworkGraph = ({
  highchartsOptions,
  handleLinkClick,
  handleRefreshChart,
  linksFilterItems,
  linksFilterValue,
  nodesFilterItems,
  nodesFilterValue,
}) => {
  const [localLinksFilterValue, setLocalLinksFilterValue] = useState(linksFilterValue);
  const [localNodesFilterValue, setLocalNodesFilterValue] = useState(nodesFilterValue);
  const [currentScale, setCurrentScale] = useState(1);
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const chartRef = useRef();

  const onMouseOverCheckboxItem = (checkboxValue, itemsType) => {
    if ((itemsType === 'links' && linksFilterValue.indexOf(checkboxValue) > -1)
      || (itemsType === 'nodes' && nodesFilterValue.indexOf(checkboxValue) > -1)) {
      const nodes = _.get(chartRef, 'current.chart.series[0].nodes', []);
      const links = _.get(chartRef, 'current.chart.series[0].points', []);
      nodes.forEach((chartNode) => {
        chartNode.graphic.attr({
          opacity: 0.1,
        });
        if (chartNode.dataLabel.attr('originalOpacity') > 0) {
          chartNode.dataLabel.attr({
            opacity: 0.1,
          });
        }
      });
      links.forEach((chartLine) => {
        chartLine.graphic.attr({
          opacity: 0.1,
        });
        if (chartLine.options.hasSiblings) {
          chartLine.graphic.attr({
            opacity: 0,
          });
        }
      });
      links.forEach((chartLine) => {
        const { fromNode, toNode } = chartLine;
        let shouldShowFromNode = false;
        let shouldShowToNode = false;
        let shouldShowLink = false;
        if (itemsType === 'links') {
          if (chartLine.options.type === checkboxValue) {
            shouldShowFromNode = true;
            shouldShowToNode = true;
            shouldShowLink = true;
          }
        } else {
          if (fromNode.options.group === checkboxValue) {
            shouldShowFromNode = true;
          }
          if (toNode.options.group === checkboxValue) {
            shouldShowToNode = true;
          }
          if (shouldShowFromNode && shouldShowToNode) {
            shouldShowLink = true;
          }
        }
        if (shouldShowFromNode) {
          fromNode.graphic.attr({ opacity: 1 });
          fromNode.dataLabel.attr({ opacity: 1 });
        }
        if (shouldShowToNode) {
          toNode.graphic.attr({ opacity: 1 });
          toNode.dataLabel.attr({ opacity: 1 });
        }
        if (shouldShowLink) {
          chartLine.graphic.attr({ opacity: 1 });
        }
      });
    }
  };

  const onMouseOutCheckboxItem = (checkboxValue, itemsType) => {
    if ((itemsType === 'links' && linksFilterValue.indexOf(checkboxValue) > -1)
      || (itemsType === 'nodes' && nodesFilterValue.indexOf(checkboxValue) > -1)) {
      const nodes = _.get(chartRef, 'current.chart.series[0].nodes', []);
      const links = _.get(chartRef, 'current.chart.series[0].points', []);
      nodes.forEach((chartNode) => {
        chartNode.graphic.attr({
          opacity: 1,
        });
        chartNode.dataLabel.attr({
          opacity: chartNode.dataLabel.attr('originalOpacity'),
        });
      });
      links.forEach((chartLink) => {
        chartLink.graphic.attr({
          opacity: 1,
        });
      });
    }
  };

  const defaultOptions = {
    highcharts: Highcharts,
    options: {
      chart: {
        type: 'networkgraph',
        events: {
          render: function load() {
            const chart = this;
            const { nodes } = chart.series[0];
            nodes.forEach((node) => {
              const { graphic: chartNode, dataLabel } = node;
              const originalOpacity = dataLabel.attr('opacity');
              if (chartNode) {
                dataLabel.attr({
                  originalOpacity,
                });
              }
            });
          },
          load: function load() {
            const chart = this;
            const { nodes, points: links } = chart.series[0];
            nodes.forEach((node) => {
              const { graphic: chartNode, dataLabel } = node;
              const originalOpacity = dataLabel.attr('opacity');
              if (chartNode) {
                dataLabel.text.attr({
                  class: 'panningDisabled',
                });
                chartNode.on('mouseover', () => {
                  dataLabel.attr({
                    opacity: 1,
                  });
                });
                chartNode.on('mouseout', () => {
                  dataLabel.attr({
                    opacity: originalOpacity,
                  });
                });
              }
            });
            links.forEach((chartLine) => {
              const { fromNode, toNode } = chartLine;
              chartLine.svgPadding.on('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                document.getElementById('networkgraph-links-tooltip').style.display = 'none';
                handleLinkClick(fromNode, toNode);
              });
            });
          },
        },
      },
      credits: { enabled: false },
      plotOptions: {
        networkgraph: {
          keys: ['from', 'to'],
          layoutAlgorithm: {
            enableSimulation: false,
            maxIterations: 100,
            integration: _.get(highchartsOptions, 'options.series[0].nodes', []).length > 50 ? 'verlet' : 'euler',
          },
        },
        series: {
          dataLabels: {
            enabled: true,
            linkFormat: '',
            style: {
              transition: 'opacity 400ms',
              strokeWidth: '1',
            },
          },
        },
      },
      tooltip: false,
      title: { text: 'Titre Network Graph' },
      series: [{
        link: {
          width: 1,
        },
        draggable: true,
      }],
    },
  };

  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  const handleToggleFilterValue = (event, itemsType) => {
    const {
      target: { value, checked },
    } = event;
    let localFilterValue;
    if (itemsType === 'links') {
      localFilterValue = localLinksFilterValue;
    } else {
      localFilterValue = localNodesFilterValue;
    }
    let finalFilterValue;
    if (checked) {
      finalFilterValue = [...localFilterValue, value];
    } else {
      finalFilterValue = _.without(localFilterValue, value);
    }
    if (itemsType === 'links') {
      setLocalLinksFilterValue(finalFilterValue);
    } else {
      setLocalNodesFilterValue(finalFilterValue);
    }
  };

  const handleChangeFilterValue = (event, itemsType) => {
    const {
      target: { value },
    } = event;
    const finalFilterValue = typeof value === 'string' ? value.split(',') : value;
    if (itemsType === 'links') {
      setLocalLinksFilterValue(finalFilterValue);
    } else {
      setLocalNodesFilterValue(finalFilterValue);
    }
  };

  let mainBoxProps = { display: 'flex' };
  let filtersBoxSxProps = {
    borderLeft: '1px solid #dadada',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    height: '100%',
    pl: 1,
    width: '250px',
  };
  if (smallerThanLarge) {
    mainBoxProps = { component: 'span' };
    filtersBoxSxProps = {
      backgroundColor: '#ffffff',
      bottom: '8px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      ml: 1,
      position: 'absolute',
      zIndex: 1,
    };
  }
  return (
    <Box height="100%" width="100%" {...mainBoxProps}>
      <Box height="100%" width="100%" flexGrow="1" position="relative">
        <TransformWrapper
          panning={{ excluded: ['highcharts-point', 'panningDisabled'] }}
          onZoomStop={({ state: { scale } }) => setCurrentScale(scale)}
        >
          {({ resetTransform }) => (
            <Fragment>
              {currentScale > 1 && (
                <Button
                  color="primary"
                  sx={{
                    zIndex: 1,
                    position: 'absolute',
                    backgroundColor: '#ffffff',
                    top: '8px',
                    right: '8px',
                  }}
                  variant="text"
                  onClick={() => {
                    resetTransform();
                    setCurrentScale(1);
                  }}
                >
                  {t('dashboard.reset_zoom')}
                </Button>
              )}
              <TransformComponent>
                <HighchartsReact
                  ref={chartRef}
                  {...finalConfig}
                  allowChartUpdate={false}
                  immutable={false}
                />
              </TransformComponent>
            </Fragment>
          )}
        </TransformWrapper>
      </Box>
      <Box
        sx={filtersBoxSxProps}
      >
        {/* SOIT un SELECT de filtres en bas du graphe en mobile, SOIT une colonne à droite en desktop */}
        {smallerThanLarge ? (
          <Fragment>
            {linksFilterItems && (
              <Select
                onChange={(event) => handleChangeFilterValue(event, 'links')}
                startAdornment={(
                  <InputAdornment position="start">
                    {t('dashboard.links_types')}
                  </InputAdornment>
                )}
                multiple
                value={localLinksFilterValue}
                sx={{
                  backgroundColor: '#ffffff',
                  maxWidth: '400px',
                }}
                variant="outlined"
                renderValue={
                  (selected) => selected.reduce((acc, v, index) => {
                    if (index > 0) acc.push(', ');
                    const item = _.find(linksFilterItems, { value: v });
                    acc.push(<span key={item.value} style={{ color: item?.color }}>{item.name}</span>);
                    return acc;
                  }, [])
                }
              >
                {linksFilterItems.map((linksFilterItem) => (
                  <MenuItem key={linksFilterItem.name} value={linksFilterItem.value}>
                    <Checkbox
                      checked={localLinksFilterValue.indexOf(linksFilterItem.value) > -1}
                      checkedIcon={(
                        linksFilterValue.indexOf(linksFilterItem.value) > -1 ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlined />
                        )
                      )}
                      sx={{
                        '&.Mui-checked': {
                          color: linksFilterItem.color || theme.palette.primary,
                        },
                      }}
                    />
                    <ListItemText primary={linksFilterItem.name} />
                  </MenuItem>
                ))}
              </Select>
            )}
            <Select
              onChange={(event) => handleChangeFilterValue(event, 'nodes')}
              startAdornment={(
                <InputAdornment position="start">
                  {t('dashboard.nodes_types')}
                </InputAdornment>
              )}
              multiple
              value={localNodesFilterValue}
              sx={{
                backgroundColor: '#ffffff',
                maxWidth: '400px',
              }}
              variant="outlined"
              renderValue={
                (selected) => selected.reduce((acc, v, index) => {
                  if (index > 0) acc.push(', ');
                  const item = _.find(nodesFilterItems, { value: v });
                  acc.push(<span key={item.value} style={{ color: item?.color }}>{item.name}</span>);
                  return acc;
                }, [])
              }
            >
              {nodesFilterItems.map((nodesFilterItem) => (
                <MenuItem key={nodesFilterItem.name} value={nodesFilterItem.value}>
                  <Checkbox
                    checkedIcon={(
                      nodesFilterValue.indexOf(nodesFilterItem.value) > -1 ? <CheckBoxIcon /> : <CheckBoxOutlined />
                    )}
                    sx={{
                      '&.Mui-checked': {
                        color: nodesFilterItem.color || theme.palette.primary,
                      },
                    }}
                    checked={localNodesFilterValue.indexOf(nodesFilterItem.value) > -1}
                  />
                  <ListItemText primary={nodesFilterItem.name} />
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        ) : (
          <Fragment>
            {linksFilterItems && (
              <Fragment>
                <Box
                  sx={{
                    color: 'rgb(51, 51, 51)',
                    fontSize: '18px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: '100',
                    fill: 'rgb(51, 51, 51)',
                    mb: 0.5,
                  }}
                >
                  {t('dashboard.links_types')}
                </Box>
                <Box display="flex" flexDirection="column" overflow="auto" mb={3}>
                  {linksFilterItems.map((linksFilterItem) => (
                    <FormControlLabel
                      key={linksFilterItem.name}
                      value={linksFilterItem.value}
                      onMouseOver={() => onMouseOverCheckboxItem(linksFilterItem.value, 'links')}
                      onMouseOut={() => onMouseOutCheckboxItem(linksFilterItem.value, 'links')}
                      onClick={(event) => handleToggleFilterValue(event, 'links')}
                      control={(
                        <Checkbox
                          checked={localLinksFilterValue.indexOf(linksFilterItem.value) > -1}
                          checkedIcon={(
                            linksFilterValue.indexOf(linksFilterItem.value) > -1 ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlined />
                            )
                          )}
                          sx={{
                            '&.Mui-checked': {
                              color: linksFilterItem.color || theme.palette.primary,
                            },
                          }}
                        />
                      )}
                      label={<Typography noWrap>{linksFilterItem.name}</Typography>}
                    />
                  ))}
                </Box>
              </Fragment>
            )}
            <Box
              sx={{
                color: 'rgb(51, 51, 51)',
                fontSize: '18px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: '100',
                fill: 'rgb(51, 51, 51)',
                mb: 0.5,
              }}
            >
              {t('dashboard.nodes_types')}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              overflow="auto"
              flexBasis={linksFilterItems ? '250px' : 'unset'}
              flexShrink="0"
            >
              {nodesFilterItems.map((nodesFilterItem) => (
                <FormControlLabel
                  key={nodesFilterItem.name}
                  value={nodesFilterItem.value}
                  onMouseOver={() => onMouseOverCheckboxItem(nodesFilterItem.value, 'nodes')}
                  onMouseOut={() => onMouseOutCheckboxItem(nodesFilterItem.value, 'nodes')}
                  onClick={(event) => handleToggleFilterValue(event, 'nodes')}
                  control={(
                    <Checkbox
                      checked={localNodesFilterValue.indexOf(nodesFilterItem.value) > -1}
                      checkedIcon={(
                        nodesFilterValue.indexOf(nodesFilterItem.value) > -1 ? (
                          <CheckCircle />
                        ) : (
                          <CheckCircleOutline />
                        )
                      )}
                      sx={{
                        '&.Mui-checked': {
                          color: nodesFilterItem.color || theme.palette.primary,
                        },
                      }}
                    />
                  )}
                  label={<Typography noWrap>{nodesFilterItem.name}</Typography>}
                />
              ))}
            </Box>
          </Fragment>
        )}
        <Button
          color="secondary"
          sx={{ ml: smallerThanLarge ? 0 : 1, mt: smallerThanLarge ? 0 : 1 }}
          onClick={() => handleRefreshChart(localNodesFilterValue, localLinksFilterValue)}
        >
          {t('dashboard.filter_chart_items')}
        </Button>
      </Box>
    </Box>
  );
};

NetworkGraph.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
  handleLinkClick: PropTypes.func.isRequired,
  handleRefreshChart: PropTypes.func.isRequired,
  linksFilterValue: PropTypes.arrayOf(PropTypes.string),
  linksFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  nodesFilterValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  nodesFilterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

NetworkGraph.defaultProps = {
  linksFilterValue: null,
  linksFilterItems: null,
};

export default NetworkGraph;
