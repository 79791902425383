export const types = {
  CREATE_MAILING_LIST: '@MAILING_LIST/CREATE_MAILING_LIST',
  CREATE_MAILING_LIST_SUCCESS: '@MAILING_LIST/CREATE_MAILING_LIST_SUCCESS',
  CREATE_MAILING_LIST_ERROR: '@MAILING_LIST/CREATE_MAILING_LIST_ERROR',

  CLEANUP_MAILING_LISTS: '@ALERTS/CLEANUP_MAILING_LISTS',

  CLOSE_MAILING_LIST_DIALOG: '@MAILING_LIST/CLOSE_MAILING_LIST_DIALOG',

  DELETE_MAILING_LISTS: '@MAILING_LIST/DELETE_MAILING_LISTS',
  DELETE_MAILING_LISTS_SUCCESS: '@MAILING_LIST/DELETE_MAILING_LISTS_SUCCESS',
  DELETE_MAILING_LISTS_ERROR: '@MAILING_LIST/DELETE_MAILING_LISTS_ERROR',

  EDIT_MAILING_LIST: '@MAILING_LIST/EDIT_MAILING_LIST',
  EDIT_MAILING_LIST_SUCCESS: '@MAILING_LIST/EDIT_MAILING_LIST_SUCCESS',
  EDIT_MAILING_LIST_ERROR: '@MAILING_LIST/EDIT_MAILING_LIST_ERROR',

  FETCH_MAILING_LISTS: '@MAILING_LIST/FETCH_MAILING_LISTS',
  FETCH_MAILING_LISTS_SUCCESS: '@MAILING_LIST/FETCH_MAILING_LISTS_SUCCESS',
  FETCH_MAILING_LISTS_ERROR: '@ALERTS/FETCH_MAILING_LISTS_ERROR',

  OPEN_MAILING_LIST_DIALOG: '@MAILING_LIST/OPEN_MAILING_LIST_DIALOG',
};

export const cleanupMailingLists = () => ({
  type: types.CLEANUP_MAILING_LISTS,
});

export const fetchMailingLists = () => ({
  type: types.FETCH_MAILING_LISTS,
});

export const fetchMailingListsSuccess = (mailingLists) => ({
  type: types.FETCH_MAILING_LISTS_SUCCESS,
  mailingLists,
});

export const fetchMailingListsError = (response) => ({
  type: types.FETCH_MAILING_LISTS_ERROR,
  response,
});

export const openMailingListDialog = (id, name) => ({
  type: types.OPEN_MAILING_LIST_DIALOG,
  id,
  name,
});

export const closeMailingListDialog = () => ({
  type: types.CLOSE_MAILING_LIST_DIALOG,
});

export const createMailingList = (formValues) => ({
  type: types.CREATE_MAILING_LIST,
  formValues,
});

export const createMailingListSuccess = () => ({
  type: types.CREATE_MAILING_LIST_SUCCESS,
});

export const createMailingListError = () => ({
  type: types.CREATE_MAILING_LIST_ERROR,
});

export const editMailingList = (formValues) => ({
  type: types.EDIT_MAILING_LIST,
  formValues,
});

export const editMailingListSuccess = () => ({
  type: types.EDIT_MAILING_LIST_SUCCESS,
});

export const editMailingListError = () => ({
  type: types.EDIT_MAILING_LIST_ERROR,
});

export const deleteMailingListErrors = (ids) => ({
  type: types.DELETE_MAILING_LISTS,
  ids,
});

export const deleteMailingListsSuccess = () => ({
  type: types.DELETE_MAILING_LISTS_SUCCESS,
});

export const deleteMailingListsError = () => ({
  type: types.DELETE_MAILING_LISTS_ERROR,
});
