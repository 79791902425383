import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Alert from 'generic/components/pages/Alert';
import {
  activateDeactivateAlert,
  cleanupAlert,
  fetchAlert,
  fetchAlertForm,
  saveAlert,
} from 'generic/core/alerts/actions';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { emailsAsArrayFromAutocomplete, computeSearchFormInitialValues } from 'generic/utils/qesUtils';
import { fetchResults } from 'generic/core/search/actions';

const AlertContainer = () => {
  const alert = useSelector((state) => state.alerts.editAlert);
  const loading = useSelector((state) => state.alerts.loading);
  const saveAlertLoading = useSelector((state) => state.alerts.saveAlertLoading);
  const fields = useSelector((state) => state.alerts.form.champs);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!_.isEmpty(id)) {
      dispatch(fetchAlert(id));
      dispatch(fetchAlertForm(id));
    }
  }, [id, dispatch]);

  // Cleanup de l'alerte lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanupAlert())
  ), [dispatch]);

  const handleSave = (values) => {
    const params = emailsAsArrayFromAutocomplete(['email'], values);
    if (params.email) {
      params.email = params.email.join(',');
    }
    dispatch(saveAlert(params));
  };

  const handleClickSearch = (alertId) => {
    history.push('/search/results');
    dispatch(fetchResults({
      uriParams: {
        veille: alertId,
        premier: 1,
        dernier: 20,
      },
      refreshForm: true,
      clearSelection: true,
      clearResults: true,
    }));
  };

  const handleClickActivateDeactivate = (alertId, active) => {
    dispatch(activateDeactivateAlert(alertId, active));
  };

  const initialValues = computeSearchFormInitialValues(fields);
  const fieldsAdvanced = [];
  if (fields) {
    if (!_.isEmpty(initialValues)) {
      _.each(fields, (field) => {
        fieldsAdvanced.push(field);
      });
    }
  }

  if (loading || _.isEmpty(alert)) {
    return <GlobalLoading />;
  }
  return (
    <Alert
      alert={alert}
      handleSave={handleSave}
      handleClickSearch={handleClickSearch}
      handleClickActivateDeactivate={handleClickActivateDeactivate}
      saveCartLoading={saveAlertLoading}
      formInitialValues={initialValues}
      fieldsAdvanced={fieldsAdvanced}
    />
  );
};

export default AlertContainer;
