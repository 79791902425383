import i18next from 'i18next';
import _, { get } from 'lodash';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { getWeekdays } from 'generic/utils/dateUtils';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import { format, isDate } from 'date-fns';

const { CIVILITY, INPUT_FORMAT } = QES_CONSTANTS;

/**
 * Cette fonction à pour but de retourner le genre à partir de la civilité renseigné dans le profil
 * QES afin de "genrer" les traductions.
 *
 * @param {long} civilite est l'id de civilité reçu par QES
 * @returns {string} `male` ou `female` comme attendu par `i18next`
 * si un identifiant inconnu est envoyé, `male` est envoyé par défaut
 */
const civiliteToGender = (civilite) => {
  const genders = {
    [CIVILITY.miss]: 'female',
    [CIVILITY.mrs]: 'female',
    [CIVILITY.mr]: 'male',
  };

  return get(genders, civilite, 'male');
};

/**
 * retourne le séparateur pour les requêtes à QES en fonction du format de saisie du champ de la facette
 * @param {INPUT_FORMAT} inputFormat est l'id du Format de saisie
 */
const multipleValueSeparatorByInputFormat = (inputFormat) => {
  const multipleValueSeparatorByFormat = {
    [INPUT_FORMAT.dropdown_list]: ';',
    [INPUT_FORMAT.number]: ';',
    [INPUT_FORMAT.date_with_comparator]: ' OR ',
    [INPUT_FORMAT.text]: ' OR ',
    [INPUT_FORMAT.autocomplete_column_direct_regex]: ' OR ',
  };

  return get(multipleValueSeparatorByFormat, inputFormat, ' OR ');
};

/**
 * retourne une version lisible des paramètres d'envoi d'une newsletter, traduit dans la locale courante de l'app
 * Exemple: 'Lundi, Jeudi à 18h'
 * @param {object} newsletter est la newsletter telle que fournie par l'API
 */
const humanizeNewsletterSendingSchedule = (newsletter) => {
  if (_.isEmpty(newsletter) || (_.isEmpty(newsletter.jours) && _.isEmpty(newsletter.heures))) {
    return i18next.t('newsletters.manual_sending');
  }

  // Récupération de la liste des jours de la semaine "localisés"
  const weekdays = getWeekdays();

  // Traitement des jours d'envois de la newsletter
  const days = newsletter.jours;
  const daysStr = _.map(days, (day) => _.capitalize(weekdays[day - 1])).join(', ');

  // Traitement des heures d'envois de la newsletter
  const hours = newsletter.heures;
  const hoursStr = _.map(hours, (hour) => `${String(hour).padStart(2, '0')}h`).join(', ');

  return `${daysStr} ${i18next.t('newsletters.at')} ${hoursStr}`;
};

/**
 * @param {Array<String>} recipientsFields la liste des champs à transformer ('to', 'cc', 'bcc', 'destinataire')
 * @param {Object} values contient les valeurs issue de formik, dans le handleSubmit
 * @returns un objet avec le même format que l'input mais dont les clés matchées de `recipientsFields` ont été
 * nettoyées et transformées en tableau de string (ex: `bcc: ['a@b.c', 'd@e.f']` )
 */
const emailsAsArrayFromAutocomplete = (recipientsFields, values) => {
  if (_.isArray(recipientsFields) && _.isEmpty(recipientsFields)) return values;

  const res = _.merge({}, values);

  _.forOwn(values, (value, key) => {
    if (recipientsFields.includes(key)) {
      let finalValue = '';
      if (_.isArray(value)) {
        const emails = _.reduce(value, (acc, val) => {
          acc.push(_.isObject(val) ? val.email : val);
          return acc;
        }, []);
        finalValue = emails;
      } else if (_.isObject(value)) {
        finalValue = [value.email];
      }
      res[key] = finalValue;
    }
  });

  return res;
};

/**
 * @param {Array<String>} recipientsFields la liste des champs à transformer ('to', 'cc', 'bcc', 'destinataire')
 * @param {Object} values contient les valeurs issue de formik, dans le handleSubmit
 * @returns un objet avec le même format que l'input mais dont les clés matchées de `recipientsFields` ont été
 * nettoyées et transformées en tableau de string (ex: `bcc: 'a@b.c;d@e.f'` )
 */
const emailsAsStringFromAutocomplete = (recipientsFields, values) => {
  const newValues = emailsAsArrayFromAutocomplete(recipientsFields, values);
  return _.transform(newValues, (acc, value, code) => {
    acc[code] = value;
    if (recipientsFields.includes(code)) acc[code] = value.join(';');
    return acc;
  });
};

/**
 * @param {Object} base la base QES
 * @param {String} documentId l'identifiant de l'article du document QES pour lequel calculer le MoreLikeThis
 * @returns un objet de paramètres
 */
const computeMLTParams = (base, documentId) => {
  const targetFields = [];
  if (base.champTitre) {
    targetFields.push(base.champTitre);
  }
  if (base.champTexte) {
    targetFields.push(base.champTexte);
  }
  return {
    base: base.base,
    id: documentId,
    targetfields: targetFields.join(', '),
    start: 0,
    slice: 5,
    min_term_freq: 1,
    min_doc_freq: 1,
    max_doc_freq: 1000000,
    min_word_length: 0,
    max_word_length: 1000,
  };
};

const computeSearchFormInitialValues = (fields, locationParams = {}) => {
  const initialValues = {};
  _.map(fields, (field) => {
    if (field.code) {
      if (field.type === 'date_intervalle') {
        let comp = '2';
        _.each(field.comparateurs, (comparateur) => {
          if (!_.isEmpty(comparateur.selected)) {
            comp = comparateur.code;
          }
        });
        initialValues[`${field.code}_comp`] = locationParams[`${field.code}_comp`] || comp || '2';
        let from = !_.isEmpty(field.value) ? new Date(field.value) : field.value;
        // Prise en compte de la valeur éventuellement forcée dans locationParams
        if (locationParams[`${field.code}_from`]) {
          const dateParts = locationParams[`${field.code}_from`].split('/');
          from = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        }
        initialValues[`${field.code}_from`] = from || null;
        let to = !_.isEmpty(field.value2) ? new Date(field.value2) : field.value2;
        // Prise en compte de la valeur éventuellement forcée dans locationParams
        if (locationParams[`${field.code}_to`]) {
          const dateParts = locationParams[`${field.code}_to`].split('/');
          to = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        }
        initialValues[`${field.code}_to`] = to || null;
      } else if (field.type === 'liste') {
        let predicate = (value) => !_.isEmpty(value.selected);
        // Si jamais il existe une valeur forcée dans locationParams on change
        // la méthode du _.find pour matcher la valeur avec le bon code
        if (locationParams[field.code]) {
          predicate = { code: locationParams[field.code] };
        }
        const selectedValue = _.find(field.values, predicate);
        const validValue = selectedValue || field.values[0];
        initialValues[field.code] = _.get(validValue, 'code', '');
      } else if (_.includes([
        'liste_multi',
        'liste_chosen',
        'liste_checkbox_ou',
        'liste_checkbox_et_ou',
      ], field.type)) {
        let locationParamsSelected = locationParams[field.code] || [];
        if (locationParams[field.code] && !_.isArray(locationParams[field.code])) {
          locationParamsSelected = [locationParamsSelected];
        }
        const values = [];
        _.each(field.values, (value) => {
          // On prend en priorité les "codes" existants dans les locationParams
          if (locationParamsSelected.includes(value.code) || !_.isEmpty(value.selected)) {
            if (['liste_checkbox_et_ou', 'liste_checkbox_ou'].includes(field.type)) {
              values.push(value.code);
            } else {
              values.push(value);
            }
          }
        });
        initialValues[field.code] = values || [];
        if (field.type === 'liste_checkbox_et_ou') {
          // On prend en priorité le "_comp" existant dans les locationParams
          initialValues[`${field.code}_comp`] = locationParams[`${field.code}_comp`] || field.comparateur || '';
        }
      } else {
        // On prend en priorité la valeur des locationParams
        initialValues[field.code] = locationParams[field.code] || field.value || '';
      }
    }
  });

  return initialValues;
};

const getNormalizedFormValues = (values) => (
  _.transform(
    values,
    (acc, value, code) => {
      acc[code] = (
        // eslint-disable-next-line no-nested-ternary
        _.isArray(value) && _.every(value, _.isObject) ? (
          _.map(value, (item) => item.code)
        ) : (
          isDate(value) ? format(new Date(value), 'dd/MM/yyyy') : value
        )
      );
    },
  )
);

/**
 * QES étant ce qu'il est, impossible de savoir quel genre de node on a, un coup c'est un tableau d'1 valeur
 * un coup c'est une variable primitive, c'est trop imprévisible sur un même appel API on peut avoir les deux...
 * cette méthode tente de faire au mieux en prenant la première valeur du tableau de 1 objet souvent renvoyer
 * OU prend la valeur directement s'il ne s'agit pas d'un tableau
 * @param {string|array|number} node le noeud duquel extraire une valeur
 * @return {string|number|null} la valeur trouvée ou null
 */
const getValueOrFirstValueFromArray = (node) => {
  let output;
  if (_.isArray(node)) {
    [output] = node;
  } else if (undefined === node) {
    output = null;
  } else {
    output = node;
  }

  return output;
};

/**
 * Permet de récupérer l'ID de base d'un document
 * @param {Object} document le document QES
 * @return {number} ID de la base
 */
const getDocumentBaseId = (document) => {
  const { qesdocument, base: rootBaseId } = document;
  // Par défaut, l'ID de la base du document sera celui à la racine
  let docBaseId = rootBaseId;
  const qesDocumentBaseId = getValueOrFirstValueFromArray(qesdocument?.BASE_ID);
  if (qesDocumentBaseId && qesDocumentBaseId !== '1000') {
    // Mais si jamais le qesdocument dispose d'un BASE_ID
    // alors on l'utilise à la place de celui de la racine
    docBaseId = +qesDocumentBaseId;
  }
  return docBaseId;
};

/**
 * Permet de récupérer des valeurs dans `document.qesdocument` à partir de la configuration de la base.
 * Par exemple, si `base.champTitre == 'title'` on va chercher la valeur dans
 * `qesdocument.title[0]` ou `qesdocument.title`
 * @param {Object} base l'objet base de référence pour trouver les éléments de config
 * @param {Object} qesdocument l'objet QESDocument (tel que dans les propTypes) à parcourir
 * @param {String} fieldName le nom de la clé à trouver sur la base pour en sortir la clé dans QESDocument
 */
const getValueByBaseFieldsConfig = (base, qesdocument, fieldName) => {
  const node = qesdocument?.[base?.[fieldName]];
  return getValueOrFirstValueFromArray(node);
};

/**
 * Permet de calculer une URL absolue, authentifié vers une ressource QES.
 * @param {String} baseId l'id de la base dont dépend la ressource
 * @param {String} resourceName le subpath vers la ressource
 * @returns un lien, absolue et authentifié, vers la resource
 */
const generateLinkToResource = (baseId, resourceName) => {
  const { API_ENTRY_POINT } = QES_CONSTANTS;

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  const baseUrl = `${API_ENTRY_POINT}/file?`;
  const url = new URL(baseUrl);

  // Ajouter des paramètres à l'URL
  url.searchParams.append('base', baseId);
  url.searchParams.append('logon', logon);
  url.searchParams.append('key', key);
  url.searchParams.append('file', resourceName);

  return url.toString();
};

/**
 * Permet de récupérer la stratégie par défaut d'une base.
 * @param {String} baseId l'id de la base
 * @returns object de la stratégie de la base demandée
 */
const getActiveBaseDefaultSearch = (baseId) => {
  const { DEFAULT_SEARCH_PER_BASE } = QES_CONSTANTS;
  if (DEFAULT_SEARCH_PER_BASE?.[baseId]) {
    return DEFAULT_SEARCH_PER_BASE?.[baseId];
  }

  return DEFAULT_SEARCH_PER_BASE.default;
};

/**
 * Permet de créer une "balise" d'annotation. Exemple:
 * <span class="html-tag Person" type-text="Personne">Daniel Peck</span>
 * @param {String} annotationType le type de l'annotation, exemple 'Person', 'Concept'...
 * @param {String} annotation le texte de l'annotation, exemple 'Donald Trump', 'Apple'...
 * @param {Boolean} fullTag précise si on veut tout le tag (par défaut ne renverra que la balise ouvrante)
 * @returns {String} l'annotation dans un span au format texte
 */
const createHtmlAnnotationTag = (annotationType, annotation, fullTag) => {
  const translationText = i18next.t(
    `annotations.${annotationType}`,
    { defaultValue: annotationType },
  );
  const opening = `<span
    class="html-tag ${annotationType}"
    type-text="${translationText}"
  >`;
  if (fullTag) {
    return `${opening}${annotation}</span>`;
  }
  return opening;
};

export {
  civiliteToGender,
  computeMLTParams,
  computeSearchFormInitialValues,
  createHtmlAnnotationTag,
  emailsAsArrayFromAutocomplete,
  emailsAsStringFromAutocomplete,
  generateLinkToResource,
  getActiveBaseDefaultSearch,
  getDocumentBaseId,
  getNormalizedFormValues,
  getValueByBaseFieldsConfig,
  getValueOrFirstValueFromArray,
  humanizeNewsletterSendingSchedule,
  multipleValueSeparatorByInputFormat,
};
