import { types } from 'generic/core/mailingLists/actions';

const mailingListReducer = (
  state = {
    mailingLists: [],
    loading: true,
    dialogMailingList: {},
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_MAILING_LISTS: {
      return {
        ...state,
        mailingLists: [],
      };
    }
    case types.FETCH_MAILING_LISTS: {
      return {
        ...state,
        mailingLists: [],
        loading: true,
      };
    }
    case types.FETCH_MAILING_LISTS_SUCCESS: {
      return {
        ...state,
        mailingLists: action.mailingLists,
        loading: false,
      };
    }
    case types.FETCH_MAILING_LISTS_ERROR: {
      return {
        ...state,
        mailingLists: [],
        loading: false,
      };
    }
    case types.OPEN_MAILING_LIST_DIALOG: {
      return {
        ...state,
        dialogMailingList: {
          open: true,
          idMailingList: action.id,
          nameMailingList: action.name,
        },
      };
    }
    case types.CLOSE_MAILING_LIST_DIALOG: {
      return {
        ...state,
        dialogMailingList: {
          open: false,
        },
      };
    }

    case types.CREATE_MAILING_LIST: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: true,
        },
      };
    }

    case types.CREATE_MAILING_LIST_SUCCESS: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
          open: false,
        },
      };
    }

    case types.CREATE_MAILING_LIST_ERROR: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
        },
      };
    }

    case types.EDIT_MAILING_LIST: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: true,
        },
      };
    }

    case types.EDIT_MAILING_LIST_SUCCESS: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
          open: false,
        },
      };
    }

    case types.EDIT_MAILING_LIST_ERROR: {
      return {
        ...state,
        dialogMailingList: {
          ...state.dialogMailingList,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default mailingListReducer;
