import _ from 'lodash';
import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import { snackActions } from 'generic/utils/snackbar';
import { searchFacet, getNetworkGraphData, searchResult } from 'generic/api/dashboard';
import QES_CONSTANTS from 'generic/core/qes/constants';
import {
  fetchWidgetError,
  fetchWidgetSuccess,
  types,
} from 'generic/core/dashboard/actions';
import { buildBarStacked, buildPolar, buildSankey } from 'generic/utils/qes2highcharts';
import {
  getTokenFromStorage,
  getLogonFromStorage,
} from 'generic/utils/utils';

const { API_ENTRY_POINT } = QES_CONSTANTS;

function* workFetchWidget(widgetId, widget, params) {
  try {
    let results = {};
    const newParams = { ...params };
    if (!_.isEmpty(params.additionalQuery)) {
      newParams.query = `${newParams.query} AND ${newParams.additionalQuery}`;
    }

    if (widget.type === 'networkgraph') {
      results = yield call(getNetworkGraphData, newParams);
    } else if (widget.type === 'documentlist') {
      results = yield call(searchResult, newParams);
    } else {
      results = yield call(searchFacet, newParams);
    }

    let widgetData = {};
    const series = [];
    let axisXCategories = [];
    let axisYCategories = [];

    if (!_.isEmpty(results)) {
      if (newParams.exportWidget && results.response.fichier) {
        const url = new URL(`${API_ENTRY_POINT}/export`);
        const obj = {
          key: getTokenFromStorage(),
          logon: getLogonFromStorage(),
          fichier: results.response.fichier,
        };
        url.search = new URLSearchParams(obj);
        window.open(url, '_self').focus();
      } else if (widget.type === 'bar_stacked') {
        const result = buildBarStacked(results, widget.aggregates[0], widget.pivots[0]);
        axisXCategories = result.xAxis.categories;
        axisYCategories = result.yAxis.categories;
        _.merge(series, result.series);
      } else if (widget.type === 'documentlist') {
        const elasticResults = _.get(results, 'response.data.docs', []);
        series.push(elasticResults);
      } else {
        const elasticFacets = _.get(results, 'response.facets', {});
        for (let aggregateIndex = 0; aggregateIndex < widget.aggregates.length; aggregateIndex++) {
          const aggregateName = widget.aggregates[aggregateIndex];
          const serieName = _.get(widget, ['seriesNames', aggregateIndex], `serie ${aggregateIndex}`);
          const level1Aggregate = elasticFacets[aggregateName];
          let data = [];
          let nodes = [];

          if (widget.type === 'polar') {
            const uniqCatX = new Set();
            _.each(level1Aggregate, (agg1) => {
              uniqCatX.add(agg1.key);
            });
            axisXCategories = [...uniqCatX].sort();
            series.push({
              name: serieName,
              data: buildPolar(results, aggregateName),
            });
          } else if (widget.type === 'sankey') {
            series.push({
              name: serieName,
              data: buildSankey(results, aggregateName),
              keys: ['from', 'to', 'weight'],
            });
          } else if (widget.pivots) {
            for (let pivotIndex = 0; pivotIndex < widget.pivots.length; pivotIndex++) {
              const pivotName = widget.pivots[pivotIndex];
              let point = {};

              switch (widget.type) {
                case 'sunburst': {
                  const rootNode = {
                    id: '0.0',
                    parent: '',
                    name: i18next.t('dashboard.root'),
                  };
                  const level1 = [];
                  const level2 = [];

                  _.each(level1Aggregate, (agg1) => {
                    point = {
                      id: agg1.key,
                      name: agg1.lib,
                      value: agg1.doc_count,
                      parent: rootNode.id,
                    };
                    level1.push(point);

                    _.each(agg1[pivotName]?.buckets, (agg2) => {
                      point = {
                        name: agg2.key,
                        value: agg2.doc_count,
                        parent: agg1.key,
                      };

                      level2.push(point);
                    });
                  });

                  series.push({
                    data: [
                      rootNode,
                      ...level1,
                      ...level2,
                    ],
                  });
                  break;
                }

                case 'scatter3d': {
                  const seriesData = [];
                  _.each(level1Aggregate, (agg1) => {
                    _.each(agg1[pivotName].buckets, (agg2) => {
                      point = [
                        agg2.key,
                        agg2.doc_count,
                        agg1.key,
                      ];
                      seriesData.push(point);
                    });
                  });

                  series.push({
                    custom: seriesData.reduce((acc, cur) => ({
                      minMaxX: [
                        Math.min(_.get(acc, 'minMaxX[0]', cur[0]), cur[0]),
                        Math.max(_.get(acc, 'minMaxX[1]', cur[0]), cur[0]),
                      ],
                      minMaxY: [
                        Math.min(_.get(acc, 'minMaxY[0]', cur[1]), cur[1]),
                        Math.max(_.get(acc, 'minMaxY[1]', cur[1]), cur[1]),
                      ],
                      minMaxZ: [
                        Math.min(_.get(acc, 'minMaxZ[0]', cur[2]), cur[2]),
                        Math.max(_.get(acc, 'minMaxZ[1]', cur[2]), cur[2]),
                      ],
                    }), {
                      minMaxX: [undefined, undefined],
                      minMaxY: [undefined, undefined],
                      minMaxZ: [undefined, undefined],
                    }),
                    data: seriesData,
                  });
                  break;
                }

                case 'heatmap': {
                  const uniqCatY = new Set();
                  const uniqCatX = new Set();

                  _.each(level1Aggregate, (agg1) => {
                    uniqCatX.add(agg1.key);
                    _.each(agg1[pivotName]?.buckets, (agg2) => {
                      uniqCatY.add(agg2.key);
                    });
                  });

                  axisXCategories = [...uniqCatX].sort();
                  axisYCategories = [...uniqCatY].sort();

                  const serieData = [];
                  for (let index = 0; index < level1Aggregate.length; index++) {
                    const agg1 = level1Aggregate[index];
                    for (let indexY = 0; indexY < axisYCategories.length; indexY++) {
                      const categoryYName = axisYCategories[indexY];
                      const y = indexY;
                      const buckets = agg1[pivotName]?.buckets || {};
                      const value = _.find(buckets, ['key', categoryYName], {})?.doc_count || null;
                      serieData.push([index, y, value]);
                    }
                  }

                  point = {
                    name: serieName,
                    data: serieData,
                  };

                  series.push(point);
                  break;
                }

                case 'bar':
                case 'line':
                case 'spline': {
                  const serieData = level1Aggregate.map((aggregate) => (
                    [
                      aggregate.key,
                      aggregate[pivotName].value,
                    ]
                  ));

                  series.push({
                    name: serieName,
                    data: serieData,
                  });
                  break;
                }

                default: {
                  point = {};
                  data = [];
                }
              }
            }
          } else {
            let minFreq;
            let maxFreq;

            switch (widget.type) {
              case 'spline': {
                data = level1Aggregate.map((aggregate) => ([
                  aggregate.key, // timestamp
                  aggregate.doc_count, // value
                ]));
                break;
              }

              case 'networkgraph': {
                data = _.get(results, 'dataHighcharts', []);
                if (data.length > 0 && data[0].type) {
                  // Dans le cadre d'un graphe de relation, pour traiter au mieux les liens
                  // on va leur ajouter un id
                  const ids = [];
                  data = data.map(
                    (link) => {
                      let generatedId = crypto.randomUUID();
                      while (ids.includes(generatedId)) {
                        generatedId = crypto.randomUUID();
                      }
                      return {
                        ...link,
                        generatedId,
                      };
                    },
                  );
                  const multiplesLinksData = [];
                  // On va ensuite comparer les liens entre eux pour trouver les liens "communs" entre
                  // chaque paire de nodes.
                  // Objectif: crééer un noeud qui sera "par dessus" les autres, de couleur noire,
                  // et qui contiendra la liste de tous les liens communs
                  data.forEach(
                    (link) => {
                      // Si on a pas encore créé de lien multiple "spécial" avec les noeuds communs
                      if (!_.some(multiplesLinksData, (m) => _.isEqual(m.itemsTitles, [link.from, link.to]))) {
                        const relatedLinks = [];
                        let isBidirectional = false;
                        // On compare le lien avec tous les autres
                        data.forEach(
                          (compareLink) => {
                            // On vérifie si le lien comparé n'est pas lui même et qu'on a bien
                            // une équivalence entre les deux noeuds (dans un sens ou dans l'autre)
                            if (link.generatedId !== compareLink.generatedId
                              && (
                                (compareLink.from === link.from && compareLink.to === link.to)
                                || (compareLink.from === link.to && compareLink.to === link.from)
                              )
                            ) {
                              // On met dans les liens communs le lien de départ, pour commencer
                              if (relatedLinks.length === 0) {
                                relatedLinks.push(link);
                                // On ajoute une prop au lien de départ pour son opacification au
                                // survol de la "légende"
                                // eslint-disable-next-line no-param-reassign
                                link.hasSiblings = true;
                              }
                              // eslint-disable-next-line no-param-reassign
                              compareLink.hasSiblings = true;
                              // On ajoute le lien comparé aux liens communs
                              relatedLinks.push(compareLink);
                              // On va ensuite tester si le lien peut être considéré comme "bidirecionnel"
                              if ((!isBidirectional && link.bidirectional)
                                || compareLink.bidirectional
                                || (compareLink.from === link.to && compareLink.to === link.from)
                              ) {
                                isBidirectional = true;
                              }
                            }
                          },
                        );
                        if (relatedLinks.length > 0) {
                          // Enfin, si on a trouvé des liens communs, on ajoute dans notre tableau
                          // de liens multiples le lien qui sera par dessus tout le monde dans le
                          // graphe
                          multiplesLinksData.push({
                            ...link,
                            type: 'multipleLinks',
                            itemsTitles: [link.from, link.to],
                            bidirectional: isBidirectional,
                            color: '#000000',
                            hasSiblings: true,
                            relatedLinks,
                          });
                        }
                      }
                    },
                  );
                  // On ajoute la tableau de liens multiples à la fin de notre tableau de liens
                  data = _.concat(data, multiplesLinksData);
                }
                nodes = _.get(results, 'nodesHighcharts', []);
                break;
              }

              case 'columnhisto':
              case 'column': {
                data = _.reduce(level1Aggregate, (acc, aggregate) => {
                  if (aggregate.doc_count > 0) {
                    acc.push(aggregate.doc_count);
                  }
                  return acc;
                }, []);
                axisXCategories = _.reduce(level1Aggregate, (acc, aggregate) => {
                  if (aggregate.doc_count > 0) {
                    acc.push(aggregate.key);
                  }
                  return acc;
                }, []);
                break;
              }

              case 'solidgauge': {
                const total = _.sumBy(level1Aggregate, 'doc_count');
                const circleWidth = Math.min(Math.trunc(100 / level1Aggregate.length), 25);
                axisXCategories = level1Aggregate.map((aggregate, index) => ({
                  outerRadius: `${112 - (index * circleWidth) - 1}%`,
                  innerRadius: `${112 - circleWidth - (index * circleWidth)}%`,
                  borderWidth: 0,
                }));
                // eslint-disable-next-line no-loop-func
                data = level1Aggregate.map((aggregate, index) => ({
                  name: aggregate.key,
                  data: [{
                    radius: axisXCategories[index].outerRadius,
                    innerRadius: axisXCategories[index].innerRadius,
                    name: aggregate.key,
                    y: Math.round((aggregate.doc_count / total) * 100),
                  }],
                }));
                break;
              }

              case 'bar':
              case 'datatable':
              case 'map':
              case 'treemap':
              case 'wordcloud': {
                data = level1Aggregate.map((aggregate) => {
                  minFreq = Math.min(minFreq || aggregate.doc_count, aggregate.doc_count);
                  maxFreq = Math.max(maxFreq || aggregate.doc_count, aggregate.doc_count);

                  return [
                    aggregate.lib || aggregate.key, // name
                    aggregate.doc_count, // value
                  ];
                });
                break;
              }

              case 'pie': {
                data = level1Aggregate.map((aggregate) => {
                  minFreq = Math.min(minFreq || aggregate.doc_count, aggregate.doc_count);
                  maxFreq = Math.max(maxFreq || aggregate.doc_count, aggregate.doc_count);

                  return {
                    name: aggregate.lib || aggregate.key, // name
                    y: aggregate.doc_count, // value
                    strategy: aggregate.key, // strategy for refining
                  };
                });
                break;
              }

              default: {
                data = [];
                nodes = [];
              }
            }

            series.push({
              name: serieName,
              data,
              nodes,
              minFreq,
              maxFreq,
            });
          }
        }
      }
    }

    if (widget.splitPerSerie) {
      for (let i = 0; i < series.length; i++) {
        yield put(fetchWidgetSuccess(`${widgetId}_${series[i].name}`, { series: series[i] }));
      }
    } else {
      widgetData = {
        axisX: {
          categories: axisXCategories,
        },
        axisY: {
          categories: axisYCategories,
        },
        series,
      };

      yield put(fetchWidgetSuccess(widgetId, widgetData));
    }
  } catch (response) {
    console.error(response);
    yield put(fetchWidgetError(widgetId, response));
    snackActions.error(i18next.t('dashboard.error_fetching_dashboard_widget', { widgetId }));
  }
}

function* workFetchWidgets({ query, widgets, baseId }) {
  if (widgets) {
    let activeBaseId = baseId;
    if (!baseId) {
      activeBaseId = yield select((state) => state.config.activeBase.base);
    }
    for (let i = 0; i < Object.keys(widgets).length; i++) {
      const widgetId = Object.keys(widgets)[i];
      const {
        facets,
        relations,
        list,
        facetmax,
        facetmax2,
        mindoccount,
        additionalQuery,
        slice,
        sort,
        exportWidget,
      } = widgets[widgetId];

      yield call(
        workFetchWidget,
        widgetId,
        widgets[widgetId],
        {
          widgetId,
          facets,
          relations,
          list,
          query,
          facetmax,
          facetmax2,
          mindoccount,
          baseId: activeBaseId,
          additionalQuery,
          slice,
          sort,
          exportWidget,
        },
      );
    }
  }
}

function* watchFetchWidgets() {
  // Petite subtilité : on utilise le takeLatest pour que tout nouveau dispatch
  // d'une des actions "écoutées" tue la thread précédente. Du coup, lorsqu'on
  // dispatch cleanupDashboard() (lorsque DashboardWrapper est unMount), le
  // takeLatest prend la main et tue le fetchWidgets en cours (ce qui empêche
  // les requêtes de chargement des widgets de continuer et le reducer d'être
  // populé)
  yield takeLatest([types.FETCH_WIDGETS, types.CLEANUP_DASHBOARD], workFetchWidgets);
}

export default {
  watchFetchWidgets,
};
