import _ from 'lodash';
import { CUSTOM_PATH } from 'generic/core/constants';
import countries from 'generic/locales/countries-en.json';
import languagesFull from 'generic/locales/language-codes-full_json.json';

const languages = languagesFull.reduce((acc, lang) => {
  if (lang.alpha2) {
    acc[lang.alpha2] = lang.English;
  }
  return acc;
}, {});

let en = {
  en: {
    translation: {
      commons: {
        months: {
          january: 'January',
          february: 'February',
          march: 'March',
          april: 'April',
          may: 'May',
          june: 'June',
          july: 'July',
          august: 'August',
          september: 'September',
          october: 'October',
          november: 'November',
          december: 'December',
        },
        short_months: {
          jan: 'Jan.',
          feb: 'Feb.',
          mar: 'Mar.',
          apr: 'Apr.',
          may: 'May',
          jun: 'Jun.',
          jul: 'Jul.',
          aug: 'Aug.',
          sep: 'Sept.',
          oct: 'Oct.',
          nov: 'Nov.',
          dec: 'Dec.',
        },
        weekdays: {
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
        },
        periods: {
          today: 'Today',
          yesterday: 'Yesterday',
          this_week: 'This week',
          last_week: 'Last week',
          last_7_days: 'Last 7 days',
          this_month: 'This month',
          last_month: 'Last month',
          last_30_days: 'Last 30 days',
          this_year: 'This year',
          last_year: 'Last year',
          last_12_months: 'Last 12 months',
        },
      },
      logon: {
        welcome: 'Welcome',
        log_in: 'Please log in',
        to_login: 'Log in',
        login: 'Login',
        password: 'Password',
        password_confirmation: 'Password confirmation',
        fail_auth: 'Your login or password is incorrect, please try again',
        forgotten_password: 'Forgotten Password?',
        forgotten_password_back: 'I have found my password',
        login_or_email: 'Login or Email',
        forgotten_password_label:
          'Please enter your login or email address for regaining access to your account.',
        forgotten_password_sent: `
          Your query has been sent, if we find any account matching your ids, we will send you an email with the reset
          procedure.
        `,
        forgotten_password_error: 'Could not send the query',
        reset_password_confirmation_title: 'Reset your password',
        reset_password_confirmation_description: 'You are now able to change your password.',
        reset_password_confirmation_success: 'Password updated',
      },
      header: {
        web_tracking: 'Web tracking',
        advanced_search: 'Advanced search',
        number_criteria_one: ' ({{count}} criteria)',
        number_criteria_other: ' ({{count}} criteria)',
        qes: 'QES',
        carts: 'Carts',
        newsletters: 'Newsletters',
        alerts: 'Alerts',
        mailing_lists: 'Mailing Lists',
        explore: 'Explore',
        dashboard: 'Dashboard',
        relations: 'Relations',
        results: 'Results',
        switch_to_question: 'Switch to question search',
        switch_to_search: 'Go back to classic search',
      },
      route: {
        results: 'Results',
        article: 'Article',
        dashboard: 'Dashboard',
        relations: 'Relations Dashboard',
        anr: 'Web tracking',
        carts: 'Carts',
        newsletters: 'Newsletters',
        alerts: 'Alerts',
        mailing_lists: 'Mailing Lists',
        users: 'Users',
        home: 'Home',
        profile: 'Profile',
        app_name: 'Infomedia Suite',
      },
      form: {
        loading_results: 'Processing search',
        ask: 'Ask your question',
        edit: 'Edit',
        edit_fast: 'Fast edit',
        search: 'Search',
        save: 'Save',
        clear: 'Clear',
        cancel: 'Cancel',
        delete: 'Delete',
        reset: 'Reset',
        title: 'Title',
        test: 'Test',
        send: 'Send',
        upload: 'Upload',
        unspecified: 'Not specified',
        upload_placeholder: 'Choose the file(s) to upload',
        omni_placeholder: 'Search in the {{activeBaseLabel}}',
        default_base_label: 'Web Knowledge Base',
        add_new_field: 'Add another field',
        errors: {
          error_from_test: 'Error when testing the settings',
          mandatory: 'Mandatory field',
          must_be_integer: 'The value must be an integer',
          must_be_more_than: 'The value must be more than',
          must_be_less_than: 'The value must be less than',
          must_select_at_least_one_lang: 'Select at least one lang',
          must_contain_email: 'Must contain at least 1 email',
          invalid_date: 'Invalid date format',
          invalid_hour: 'Invalid hour format',
          no_from_after_to: 'Start date should be before end date',
          no_past_dates: 'Dates in the past are not allowed',
          no_future_dates: 'Dates in the future are not allowed',
          min_date_message: 'Please enter a date greater than',
          max_date_message: 'Please enter a date less than',
          not_valid_email: 'Please enter a valid email',
        },
        datepicker: {
          from: '(from)',
          to: '(to)',
          placeholder: 'dd/mm/yyyy',
        },
        checkbox: {
          and: 'And',
          all: 'Select / Unselect all',
        },
        create_new_value: 'Create a new value',
      },
      config: {
        base: 'Database',
        error_fetching_config: 'Could not fetch form configuration',
        error_loading_base: 'Could not load database',
      },
      annotations: {
        Coldconcept: 'Concept',
        Concept: 'Concept',
        Person: 'Person',
        Date: 'Date',
        Location: 'Location',
        Company: 'Company',
        Product: 'Product',
        Organization: 'Organization',
        Organonoff: 'Non official organization',
        Event: 'Event',
        Media: 'Media',
        Money: 'Money',
        Object: 'Object',
        Uppers: 'Acronym',
      },
      dashboard: {
        languages,
        countries,
        error_fetching_dashboard: 'Could not fetch the dashboard',
        error_fetching_dashboard_widget: 'Could not fetch "{{widgetId}}" data',
        feeling_values: {
          '-4': 'extremely bad',
          '-3': 'very bad',
          '-2': 'bad',
          '-1': 'negative',
          0: 'neutral',
          1: 'good',
          2: 'positive',
          3: 'very positive',
          4: 'extremely positive',
        },
        root: 'Root',
        widget: {
          attack_patterns: 'Attack Patterns',
          business_concepts: 'Business Concepts',
          collect_date: 'Collection date',
          compagnies_mentionned: 'Compagnies mentionned',
          concepts: 'Concepts',
          concepts_by_country: 'Concepts by country',
          concepts_cited_by_country: 'Concepts cited by country',
          cooccurrences_graph: 'Co-occurrences graph',
          countries_mentionned: 'The countries mentionned',
          culture_concepts: 'Culture Concepts',
          documents_published_by_dates: 'Documents published by dates',
          documents_published_collected_by_dates: 'Documents published and collected by dates',
          domaine: 'Domaine',
          education_concepts: 'Education Concepts',
          energy_concepts: 'Energy Concepts',
          environmental_concepts: 'Environmental Concepts',
          events: 'Events',
          health_concepts: 'Health Concepts',
          innovation_concepts: 'Innovation Concepts',
          justice_concepts: 'Justice Concepts',
          languages: 'Languages',
          latest_published_documents: 'Latest published documents',
          malwares: 'Malwares',
          count: 'Count',
          number_articles: 'Number of articles',
          number_articles_compagnies: 'Number of articles for this company',
          number_articles_persons: 'Number of articles for this person',
          observed_data: 'Observed data',
          organizations: 'Organizations',
          organizations_mentionned: 'Organizations mentionned',
          persons_and_concepts: 'Persons and concepts',
          persons_events: 'Persons and events',
          persons_mentionned: 'Persons mentionned',
          places_mentionned: 'Places mentionned',
          places_mentionned_in_documents: 'Places mentionned in documents',
          political_concepts: 'Political Concepts',
          publication_date: 'Publication date',
          relations_graph: 'Relations graph',
          security_concepts: 'Security Concepts',
          society_concepts: 'Society Concepts',
          sources_by_original_folders: 'Sources by original folders',
          sources_mentioned: 'Sources mentioned',
          sport_concepts: 'Sport Concepts',
          status_documents: 'Status of documents',
          technology_concepts: 'Technology Concepts',
          threat_actors: 'Threat Actors',
          tonalities: 'Tonalities',
          tools: 'Tools',
          top_50_domain: 'Top 50 Domain',
          unofficial_organizations: 'Unofficial organizations',
          values: 'Values',
          part_left_type: 'Part left type',
          relation_type: 'Relation type',
          part_right_type: 'Part right type',
        },
        context_button_title: 'Export',
        download_csv: 'Download CSV',
        download_all_csv: 'Download all data CSV',
        download_jpeg: 'Download JPEG',
        download_pdf: 'Download PDF',
        download_png: 'Download PNG',
        download_svg: 'Download SVG',
        download_xls: 'Download XLS',
        exit_full_screen: 'Exit from full screen',
        print_chart: 'Print chart',
        view_full_screen: 'View in full screen',
        view_data: 'View data table',
        links_types: 'Links types',
        nodes_types: 'Nodes types',
        filter_chart_items: 'Filter items',
        reset_zoom: 'Reset zoom',
        tooltip_content: {
          source: 'Source:',
          destination: 'Destination:',
          type: 'Type:',
          weight: 'Weight:',
          bidirectional: 'Bidirectional:',
        },
      },
      ui: {
        back: 'Back',
        back_to_results: 'Back to results',
        show_in_results: 'View in results',
        show_in_dashboard: 'View in dashboard',
        yes: 'Yes',
        no: 'No',
        cancel: 'Cancel',
        close: 'Close',
      },
      results: {
        criteria: {
          edit: 'Edit the criteria',
          noedit: 'Close edition',
          add: 'With that criteria',
          remove: 'Not that criteria',
          delete: 'Delete the criteria',
          search: 'Search',
          reset: 'Reset',
          period: 'Search a period',
        },
        pagination: {
          on: 'on',
          display_rows: 'Display:',
        },
        checkbox: {
          all: 'Select all',
        },
        facets: {
          refine: 'Refine',
          show_facets: 'Show facets',
          show_more: 'show more',
          show_less: 'show less',
        },
        no_results: 'No results',
        no_search_results: 'Search returns no results',
        no_search_results_create_alert: `Search returns no results, you can create an alert
          to be notified by clicking on the button`,
        please_make_a_search: 'Please enter at least one search criteria',
        article_image: 'Article image',
        img_not_found: 'Image not found',
        error_fetching_results: 'Could not fetch results',
        total: 'total:',
        sort: 'Sort:',
        document_one: 'Document',
        document_other: 'Documents',
        relation: 'Relation',
        comments: {
          comment: 'Comment',
          hide_comments: 'Hide comments',
          show_comments_one: 'Display the comment',
          show_comments_other: 'Display the {{count}} comments',
          write_comment: 'Write a comment',
          delete: 'Delete this comment',
          deleted: 'Comment deleted',
          created: 'Comment created',
          be_alerted: 'Be alerted if a new comment is posted',
          post: 'post',
          alert_updated: 'Alert updated',
          be_alerted_disabled: `Comment alerts are only possible after you have commented on the
                                resource yourself.`,
          error_deleting_comment: 'Could not delete comment',
          error_fetching_comments: 'Could not fetch comments',
          error_submiting_comment: 'Could not submit comment',
          error_updating_comment_alert: 'Could not update comment alert',
        },
        tags: {
          more: 'Show more tags',
          less: 'Show less tags',
        },
        morelikethis: {
          see_also_label: 'See also:',
          show_see_also: 'See also',
          hide_see_also: 'Hide "see also"',
          error_fetching_morelikethis: 'Could not fetch other results',
        },
        duplicates: {
          show_duplicates: 'Show duplicates',
          hide_duplicates: 'Hide duplicates',
          error_fetching_duplicates: 'Could not fetch duplicates',
        },
        close_window: 'Close the window',
      },
      document: {
        title_undefined: 'Undefined title',
      },
      selection: {
        your_selection: 'Your selection',
        show: 'Show selection',
        remove: 'Remove from selection',
        clear: 'Clear selection',
        no_selection: 'Selection is empty',
      },
      carts: {
        choose: 'Choose a cart',
        list: 'Carts list',
        back_to_list: 'Back to carts list',
        no_carts: 'No cart',
        error_fetching_carts: 'Could not fetch carts',
        error_fetching_cart: 'Could not fetch the cart',
        create: 'Create',
        create_and_add: 'Create and add',
        create_new: 'Create a new cart',
        create_new_and_add: 'Create a new cart with the documents',
        add: 'Add',
        add_here: 'Add documents in this cart',
        name_of_new: 'Name of the new cart',
        pick_from_list: 'Pick a cart from the list',
        name: 'Name',
        date_of_creation: 'Creation date',
        visibility: 'Visibility',
        visibility_values: {
          1000: 'Private',
          1: 'Collaborative',
          2: 'Company',
          3: 'Public',
          4: 'Profile',
        },
        image: 'Image',
        owner: 'Owner',
        contributors: 'Contributors',
        number_of_documents: 'Number of documents',
        delete: 'Delete',
        delete_carts_one: 'Delete {{count}} cart',
        delete_carts_other: 'Delete {{count}} carts',
        carts_deleted_one: 'Cart deleted',
        carts_deleted_other: '{{count}} carts deleted',
        error_deleting_carts_one: 'Could not delete the cart',
        error_deleting_carts_other: 'Could not delete the {{count}} carts',
        no_document: 'No document',
        delete_cart_documents_one: 'Delete {{count}} document?',
        delete_cart_documents_other: 'Delete {{count}} documents?',
        cart_documents_deleted_one: 'Document deleted',
        cart_documents_deleted_other: '{{count}} documents deleted',
        error_deleting_cart_documents_one: 'Could not delete the document',
        error_deleting_cart_documents_other: 'Could not delete the {{count}} documents',
        cart_updated: 'Cart updated',
        error_updating_cart: 'Could not update the cart',
        docs_added_to_cart_one: '{{count}} document added to cart',
        docs_added_to_cart_other: '{{count}} documents added to cart',
        error_adding_to_cart: 'Could not add to cart',
        send_cart_documents_one: 'Send the cart with this checked document',
        send_cart_documents_other: 'Send the cart with these checked documents',
      },
      newsletters: {
        choose: 'Choose a newsletter',
        list: 'Newsletters list',
        back_to_list: 'Back to newsletters list',
        no_newsletters: 'No newsletter',
        error_fetching_newsletters: 'Could not fetch newsletters',
        error_fetching_themes: 'Could not fetch themes',
        error_fetching_newsletter: 'Could not fetch the newsletter',
        create: 'Create',
        create_and_add: 'Create and add',
        create_new: 'Create a new newsletter',
        create_new_and_add: 'Create a new newsletter with the documents',
        add: 'Add',
        add_here: 'Add documents in this newsletter',
        name_of_new: 'Name of the new newsletter',
        pick_from_list: 'Pick a newsletter from the list',
        name: 'Name',
        date_of_creation: 'Creation date',
        visibility: 'Visibility',
        visibility_values: {
          1000: 'Private',
          1: 'Collaborative',
          2: 'Company',
          3: 'Public',
          4: 'Profile',
        },
        image: 'Image',
        owner: 'Owner',
        contributors: 'Contributors',
        theme: 'Theme',
        themes: 'Themes',
        manual_mode: 'manual mode',
        topic_without_label: 'NO LABEL',
        topics: 'Topics:',
        topic: 'Rubrique',
        order: 'Ordre',
        doc_edit: 'Edit',
        sending: 'Sending',
        manual_sending: 'Manual sending',
        at: 'at',
        recipients_one: 'Recipient',
        recipients_other: 'Recipients',
        number_of_documents: 'Number of documents',
        delete: 'Delete',
        delete_newsletters_one: 'Delete {{count}} newsletter?',
        delete_newsletters_other: 'Delete {{count}} newsletters?',
        newsletters_deleted_one: 'Cart deleted',
        newsletters_deleted_other: '{{count}} newsletters deleted',
        error_deleting_newsletters_one: 'Could not delete the newsletter',
        error_deleting_newsletters_other: 'Could not delete the {{count}} newsletters',
        no_document: 'No document',
        delete_newsletter_documents_one: 'Delete {{count}} document?',
        delete_newsletter_documents_other: 'Delete {{count}} documents?',
        newsletter_documents_deleted_one: 'Document deleted',
        newsletter_documents_deleted_other: '{{count}} documents deleted',
        error_deleting_newsletter_documents_one: 'Could not delete the document',
        error_deleting_newsletter_documents_other: 'Could not delete the {{count}} documents',
        newsletter_updated: 'Cart updated',
        error_updating_newsletter: 'Could not update the newsletter',
        docs_added_to_newsletter_one: '{{count}} document added to newsletter',
        docs_added_to_newsletter_other: '{{count}} documents added to newsletter',
        error_adding_to_newsletter: 'Could not add to newsletter',
        scheduled_sending: {
          title: 'Scheduled sendings',
          hours: 'hours',
          days: 'Days:',
          morning: 'Morning:',
          afternoon: 'Afternoon:',
        },
        send_and_wipe_confirmation_title: 'Send the newsletter',
        send_and_wipe_confirmation: `
          You are about to send this newsletter, all the documents will be detached from it after sending.
          Are you sure you want to send it?
        `,
        send_and_wipe_documents: 'Send and wipe documents',
        send_success: 'Send success',
        send_error: 'Could not send the newsletter',
        send_test: 'Send test',
        send_newsletter_documents_one: 'Send the newsletter with this checked document',
        send_newsletter_documents_other: 'Send the newsletter with these checked documents',
        sent: 'Sent',
        wiped: 'Wiped',
        sending_date: 'Sending date',
        historic_list: 'Sending history',
        actions: 'Actions',
        show_sent: 'Show sent email',
        resend: 'Resend',
        resend_confirmation: `
          You are about to resend this newsletter,
          Are you sure you want to send it again?
        `,
        edit: {
          details: 'Details',
          documents: 'Documents',
          visualize: 'Visualize',
          historic: 'History',
          topics: 'Topics',
          create_topic: 'Create a topic',
          manage_topics: 'Manage topics',
          reorganizeNaturalOrder: 'Group by topics and recompute articles order',
        },
      },
      ged: {
        error_uploading_file: 'Could not upload the file',
        error_fetching_document: 'Could not fetch document',
        uploading_file_success: 'Document "{{file}}" has been successfully uploaded',
        save_document_error: 'Could not save document',
        save_document_success: 'Document saved',
        delete_documents_one: 'Delete the document?',
        delete_documents_other: 'delete the {{count}} documents?',
        delete_documents_success_one: 'The document has been successfully deleted',
        delete_documents_success_other: 'The {{count}} documents have been successfully deleted',
        delete_documents_error: 'An error has occurred',
        update_status_to_one: 'Update the document status to "{{status}}"?',
        update_status_to_other: 'Update the {{count}} documents status to "{{status}}"?',
        update_status_documents_success_one: 'The status has been successfully updated for the document',
        update_status_documents_success_other: 'The status has been successfully updated for the {{count}} documents',
        update_status_documents_error: 'Could not change status',
        update_all_status_to: 'Update all to "{{status}}"?',
        prev_doc: 'Previous document',
        next_doc: 'Next document',
        warning_concurrent_access: `You cannot update this document because the user
        "{{user}}" has already opened it for modification.`,
        document: {
          status: {
            1: 'New',
            2: 'Duplicate',
            3: 'Rejected',
            4: 'Harvested',
            5: 'Error',
            50: 'Validated',
            100: 'Published',
            200: 'Super user',
            203: 'Deleted',
            204: 'Bound',
            205: 'Merged',
            206: 'Commented',
            207: 'Duplicated',
            220: 'Ranked',
            230: 'Deduplicated',
            240: 'Favorite',
            1000: 'Unknown',
            1001: 'To complete',
          },
        },
      },
      actions: {
        actions: 'Actions',
        add_to_cart: 'Add to cart',
        add_to_newsletter: 'Add to newsletter',
        create_alert: 'Create an alert',
        manage_alert: 'Manage alerts',
        create: 'Create',
        send_to_friend: 'Send by email',
        edit: 'Modify',
        edition: 'Edit',
        comment: 'Comment',
        open_in_new_tab: 'Open in new tab',
        view_attachment: 'Show attachment',
        delete: 'Delete',
        send: 'Envoyer',
        update_status: 'Update status',
        export: 'Export',
        change_password: 'Change password',
        cancel_change_password: 'Annuler le changement de mot de passe',
      },
      alerts: {
        list: 'Alerts list',
        no_alerts: 'No alerts',
        error_fetching_alerts: 'Could not fetch alerts',
        error_fetching_alert: 'Could not fetch alert',
        details: 'Details',
        criteria: 'Criteria',
        name: 'Name',
        type: 'Type',
        date_of_creation: 'Creation date',
        type_values: {
          1: 'Alert',
          6: 'Search',
        },
        date_validity: 'Validity date',
        periodicity: 'Periodicity',
        mode_values: {
          1: 'Daily',
          2: 'Weekly',
          3: 'Monthly',
          15: 'planed',
          20: 'Every 4 hours',
          30: 'Every 2 hours',
          40: 'Every hour',
          50: 'Every 30 minutes',
          60: 'Every 15 minutes',
          70: 'Every 5 minutes',
          80: 'Every minute',
        },
        email: 'Email',
        format_values: {
          1: 'Table of content, documents on line',
          2: 'Documents',
          1000: 'Email notification, documents on line',
        },
        format_reception: 'Reception format',
        frequence: 'Frequence',
        date_of_execution: 'Execution',
        number_of_documents: 'Number of documents',
        date_of_visualisation: 'Visualisation',
        date_of_next_execution: 'Next execution',
        delete: 'Delete',
        delete_alerts_one: 'Delete {{count}} alert',
        delete_alerts_other: 'Delete {{count}} alerts',
        alerts_deleted_one: 'Alert deleted',
        alerts_deleted_other: '{{count}} alerts deleted',
        error_deleting_alerts_one: 'Could not delete the alert',
        error_deleting_alerts_other: 'Could not delete the {{count}} alerts',
        status: 'Status',
        statuses: {
          1: 'Initial',
          2: 'Normal',
          3: 'Blocked',
          4: 'Reseted',
          5: 'Disabled',
          6: 'Deleted',
          7: 'Provisional',
          1000: 'Unknown',
        },
        active_one: 'actives',
        active_other: 'actives',
        inactive_one: 'inactives',
        inactive_other: 'inactives',
        activate: 'Activate',
        deactivate: 'Deactivate',
        search: 'Search with alert criteria',
        alerts_activate_one: 'Alert activated',
        alerts_activate_other: '{{count}} alerts activated',
        alerts_deactivate_one: 'Alert deactivated',
        alerts_deactivate_other: '{{count}} alerts deactivated',
        alert_updated: 'Alert updated',
        error_updating_alert: 'Could not update the alert',
        alert_created: 'Alert "{{alert}}" created',
        error_creating_alert: 'Could not create the alert',
        alert_type_alert: 'Alert',
        alert_type_request: 'Saved request',
        error_fetching_form: 'Could not fetch search criteria',
      },
      send_to_friend: {
        send_to: 'Send to',
        recipients: 'Recipients',
        to: 'To',
        cc: 'Cc',
        bcc: 'Bcc',
        subject: 'Object',
        error_building_dialog: 'Could not prepare the sending',
        error_sending: 'Could not send the documents',
        docs_sent_one: 'Document sent',
        docs_sent_other: '{{count}} documents sent',
        display_cc_field: 'Add recipients in Cc',
        display_bcc_field: 'Add recipients in Bcc',
        comment: 'Comment',
        attachment: 'Attachment',
      },
      export: {
        select_format: 'Select the format',
        selection: 'Selection',
        massive: 'Massive',
        number_to_export_sel: 'Number of documents to export',
        number_to_export: 'Number of documents to export (max. {{max}})',
        number_exported_docs_one: 'Export one document',
        number_exported_docs_other: 'Export {{count}} documents',
        select_fields: 'Select the fields to export',
        error_exporting: 'Could not send the documents',
        docs_exported_one: 'Document exported',
        docs_exported_other: '{{count}} documents exported',
        massive_export_conf: 'The export file you asked for will be sent to you by email very soon',
      },
      users: {
        new: 'New',
        civilities: {
          mrs: 'Madam',
          miss: 'Miss',
          mr: 'Mister',
          unknown: 'Not specified',
        },
        civility: 'Civility',
        firstname: 'First name',
        lastname: 'Last name',
        languages: {
          fr: 'French',
          en: 'English',
        },
        language: 'Language',
        list: 'Users list',
        user: 'User',
        logon: 'Login',
        email: 'Email',
        password: 'Password',
        password_confirm: 'Confirm password',
        password_not_matching: 'Passwords don\'t match',
        actual_password: 'Actual password',
        new_password: 'New password',
        profile_type: 'Profile Type',
        opened: 'opened',
        closed: 'closed',
        open: 'Open',
        close: 'Close',
        edit: 'Edit',
        edit_title: 'Edit user:',
        create_title: 'Create new user',
        users_opened_one: 'User opened',
        users_opened_other: '{{count}} users opened',
        users_closed_one: 'User closed',
        users_closed_other: '{{count}} users closed',
        no_users: 'No user',
        error_fetching_users: 'Could not fetch users',
        error_updating_user: 'Could not update the user',
      },
      profile: {
        account: 'My profile',
        user_saved: 'User saved',
        user_saved_error: 'Error saving user',
        upload_avatar_error: 'Error uploading avatar',
        profile_saved: 'Profile saved',
        profile_saved_error: 'Error saving profile',
        profile_saved_warning_1: 'This password has already been used and cannot be used again',
        profile_saved_warning_2: 'Passwords don\'t match',
        profile_saved_warning_3: 'Incorrect password entered',
        upload_img_user: 'Download profile image',
        zoom: 'zoom',
        crop_image: 'Crop your image',
        avatar_saved: 'Profile image saved',
        avatar_saved_error: 'Error saving profile image',
      },
      anr: {
        opened_in_new_tab: 'This article has been opened in a new window',
        show_folders: 'Show folders',
        news: 'news',
        news_one: 'news',
        news_other: 'news',
        quotes: 'quotes',
        quotes_one: 'quote',
        quotes_other: 'quotes',
        copy: 'Copy',
        new: 'New',
        new_folder: 'New folder',
        delete_folder: 'Delete folder',
        delete_folder_sure: 'Are you sure you want to delete the folder',
        folder_deleted: 'Folder deleted',
        create_in_branch: 'Create folder in this branch',
        duplicate: 'Duplicate',
        too_much_folders: 'You can\'t create new folders',
        glimpse: 'Eye on the news',
        favorite: 'Favorite',
        favorites: 'Favorites',
        add_favorite: 'Add to favorites',
        remove_favorite: 'Remove from favorites',
        no_query: 'This folder doesn\'t have a query',
        go_to_last_validated: 'Go to last validated',
        expert_mode: 'Expert mode',
        query: 'Query',
        text_query: 'Full-text query',
        query_at_least: 'The pages must contain AT LEAST ONE of the following words',
        query_every: 'The pages must contain ALL of the following words',
        query_banned: 'The pages must contain NONE of the following words',
        quotes_query: 'Social networks query',
        partner: 'Corpus',
        partners: 'Corpuses',
        category: 'Thematic category',
        categories: 'Thematic categories',
        not_categories: 'Banned categories',
        query_twitter: 'Twitter query',
        query_twitter_help: `Twitter query help:<br/>
          AND: <i>word1 word2</i><br/>
          OR: <i>word1 OR word2</i><br/>
          AND and OR combination: <i>word1 word2 OR word3</i><br/>
          Exact expression: <i>"word1 word2 word3"</i><br/>
          Exclusion: <i>-word</i><br/>
          Hashtag: <i>#word</i><br/>
          Sent by: <i>from:pseudo</i><br/>
          Sent to: <i>to:pseudo</i><br/>
          Mentions: <i>@pseudo</i>`,
        query_linkedin: 'LinkedIn query',
        query_linkedin_help: `LinkedIn query help:<br/>
          AND: <i>word1 AND word2</i><br/>
          OR: <i>word1 OR word2</i><br/>
          Exact expression: <i>"word1 word2"</i><br/>
          Exclusion: <i>-word</i>`,
        query_youtube: 'YouTube query',
        query_youtube_help: `YouTube query help:<br/>
          AND: <i>word1 word2</i><br/>
          OR: <i>word1 | word2</i><br/>
          Exact expression: <i>"word1 word2"</i><br/>
          Exclusion: <i>-word</i>`,
        query_dailymotion: 'Dailymotion query',
        query_dailymotion_help: `Dailymotion query help:<br/>
          AND: <i>"word1 word2"</i><br/>
          OR: <i>word1 word2</i>`,
        query_twitter_min_followers: 'Minimum followers',
        filter_links_only: 'Filter quotes with links only',
        show_in_glimpse: 'Display in Eye on the news',
        focus_in_glimpse: 'Highlight in Eye on the news',
        recipients_daily_summary: 'Recipients of the daily summary',
        send_daily_summary: 'Send a daily summary by email',
        lang: 'lang',
        langs: 'langs',
        no_articles_from_test: 'No news matches the settings',
        articles_from_test: 'news matches the settings',
        other_options: 'Other options',
        error_fetching_folders: 'Could not fetch folders',
        error_fetching_folder: 'Could not fetch folder',
        error_user_fetch: "Could not fetch Ask'n'Read user",
        error_fetching_news: 'Could not fetch news',
        error_fetching_quotes: 'Could not fetch quotes',
        error_fetching_sources: 'Could not fetch sources',
        error_saving_folder: 'Could not save the folder',
        error_deleting_folder: 'Could not delete the folder',
        error_bookmarking: 'Could not save the bookmark',
        warning_unreachable_validated_article: 'The validated article is no longer available',
        folder_created: 'Folder created',
        folder_updated: 'Folder updated',
        cant_open_article_message: `The article cannot be opened in this window,
          you can open it in a new tab by clicking on the button`,
        cant_open_dark_web_article_message: `This article comes from the <strong>dark web</strong> and cannot be opened
          in this window. If you use <strong>Tor</strong> browser or something similar, you can open it in a new tab
          by clicking on the button`,
        or_copy_link_message: 'Or you can copy the link by clicking',
        cant_open_article_tip: `Tip: you can do a <strong>Ctrl + click</strong> on a news title in any news
          list to directly open it on a new tab.`,
        open_quote_link: 'Open this quote in its social network',
        no_url_for_quote: 'No news was shared in this quote',
        retweeted_by: 'Retweeted by',
        sources: 'Sources',
        site: 'Website',
        sites: 'Websites',
        date_from: 'From',
        date_to: 'To',
        folders: 'Folders',
        filter_folders: {
          label: 'Filter Folders',
          favorites: '[Favorites]',
          all: '[All]',
        },
        url: 'Address',
        type: 'Type',
        articles_count: 'News',
        address: 'Website or page address to crawl',
        comment: 'Comment',
        folders_for_source: 'You can specify the folder(s) in which you want the source to give news',
        partners_for_source: 'You can also specify the corpus(es) in which you want the source to be integrated',
        categories_for_source: `You can also specify the thematic category(ies) in which you want the
          source to be integrated`,
        demand_source: 'Ask for new source',
        demand_new_source_ok: 'New source request sent',
        demand_new_source_error: 'An error occured while asking for a new source',
        unban: 'Unban',
        unban_site: 'Unban website',
        unban_site_sure: 'Are you sure you want to unban',
        unbanned_site: 'The website is not banned anymore',
        unbanning_site_error: 'An error occured while trying to unban the website',
        name: 'Name',
        action: 'Action',
        banned_sites: 'Banned websites',
        ban_site: 'Ban website',
        ban_site_sure: 'Do you really want to ban the source',
        ban_reason_label: 'In order to improve the service, you can select one of the following reasons',
        banned_site: 'The website has been banned, it will be effective soon',
        banning_site_error: 'An error occured while trying to ban the website',
        report_extraction_title: 'Report bad text extraction of pages in website',
        report_extraction_text: `Do you want to inform the admins of a bad text extraction of pages
        in the website <strong>{{source}}</strong> ? If this article appears in your folder because of informations
        outside of the main content (for example in a section "Read more about this") you can inform
        the administrators so they can take care of the problem.`,
        reported_extraction: 'The page in the website was reported for a bad extractions',
        reported_extraction_error: 'An error occured while trying to report the page of website',
        item_one: 'item',
        item_other: 'items',
        delete_articles_error: 'An error occured while trying to delete items',
        delete_articles_ok_one: 'Item deleted',
        delete_articles_ok_other: 'Items deleted',
        warning_submit_form_host: 'Press Enter key in the text field to validate your website',
        warning_validate_host: 'Please enter a valid domain name',
        placeholder_host: 'Enter a domain',
        helperText_host: 'Use the Enter key to validate each website',
        actions: {
          ban: 'Ban',
          collect_done: 'Collect done',
          collect: 'Collect',
          delete: 'Delete',
          delete_article: 'Delete article',
          delete_quote: 'Delete quote',
          delete_sure: 'Do you really want to delete',
          error_collecting: 'An error occured while collecting',
          link_copied: 'Link copied',
          link_copy: 'Copy link',
          open_in_new_tab: 'Open in a new window',
          share_facebook: 'Share on Facebook',
          share_linkedin: 'Share on LinkedIn',
          share_of: 'Share of',
          share_twitter: 'Share on Twitter',
          share_whatsapp: 'Share on WhatsApp',
          share: 'Share',
          translate: 'Open the translated version',
          filter: 'Filter',
          filters: 'Filters',
          filterOnSite: 'Filter on site',
          filterOnFolder: 'Filter on folder',
          reset: 'Reset',
        },
      },
      account_menu: {
        signed_in_as: 'Signed in as {{ logon }}',
        logout: 'Log out',
        settings: 'Settings',
        sources: 'My sources',
      },
      mailing_lists: {
        list: 'Mailing Lists',
        users: 'Users',
        error_fetching: 'Could not fetch mailing list',
        name: 'Name',
        create_dialog: 'Create a mailing list',
        created: 'Mailing list created',
        error_creating: 'Could not create the mailing list',
        edit_dialog: 'Edit mailing list',
        edited: 'Mailing List edited',
        error_editing: 'Could not edit the mailing list',
        delete_dialog: 'Delete liste de diffussion',
        delete_one: 'Do you want to delete the following mailing list?',
        delete_other: 'Do you want to delete the following mailing lists?',
        deleted_one: '{{count}} mailing list deleted',
        deleted_other: '{{count} mailing lists deleted',
        error_deleting_one: 'Could not delete the mailing list',
        error_deleting_other: 'Could not delete mailing lists',
      },
      datatable: {
        loading: 'Loading list',
        textLabels: {
          body: {
            noMatch: 'Sorry, no matching records found',
            toolTip: 'Sort',
          },
          pagination: {
            next: 'Next Page',
            previous: 'Previous Page',
            rowsPerPage: 'Rows per page:',
            displayRows: 'of',
            jumpToPage: 'Jump to page',
          },
          toolbar: {
            search: 'Search',
            downloadCsv: 'Download CSV',
            print: 'Print',
            viewColumns: 'View Columns',
            filterTable: 'Filter Table',
          },
          filter: {
            all: 'All',
            title: 'FILTERS',
            reset: 'RESET',
          },
          viewColumns: {
            title: 'Show Columns',
            titleAria: 'Show/Hide Table Columns',
          },
          selectedRows: {
            text: 'row(s) selected',
            delete: 'Delete',
            deleteAria: 'Delete Selected Rows',
          },
        },
      },
    },
  },
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: enCustom } = require(`custom/${CUSTOM_PATH}/locales/en`);
    en = _.merge(en, enCustom);
  } catch (err) {
    console.info('Pas de fichier custom en');
  }
}

const finalEn = en;
export default finalEn;
